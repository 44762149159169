import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getDemandById, unlockDemand } from '../../api/services/demandsServices';
import { useSelector } from 'react-redux';
import { CircularProgress, Container, Typography, Grid, Card, CardHeader, CardContent, ThemeProvider, List, ListItem, Button, Badge, Chip } from '@material-ui/core';
import { theme as instajobTheme } from '../../theme'
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';
import { Person, Phone, PinDrop, ArrowLeft, Timelapse, MyLocation, AccessTime } from '@material-ui/icons';
import { TextIcon, InlinePanel } from './styles';
import { getTimeSince } from '../../helpers';
import ModalLogin from '../../features/Modals/ModalLogin';

const darkTheme = {
    ...instajobTheme,
    palette: {
        ...instajobTheme.palette,
        background: {
            default: instajobTheme.palette.primary.main,
            paper: instajobTheme.palette.primary.main
        },
        text: {
            primary: '#fff'
        }
    }
}

export const DemandDetailsScreen = () => {
    const { width: windowWidth } = useWindowDimensions();
    const { idDemand } = useParams();
    const history = useHistory();
    const { demand, isLoading, isUnlockingDemand } = useSelector(states => ({
        demand: states.demands.demandById,
        isLoading: states.demands.isRequestingDemandById,
        isUnlockingDemand: states.demands.isRequestingUnlockDemand
    }));
    const [showLoginModal, setShowLoginModal] = useState(false);

    useEffect(() => {
        if (Boolean(idDemand)) {
            getDemandById(idDemand);
        }
    }, [idDemand]);

    if (isLoading) {
        return (
            <section style={{
                background: 'linear-gradient(to bottom, #d2d2d2, transparent)', color: 'white',
                paddingTop: windowWidth > 960 ? 200 : 100,
                paddingRight: 10,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <CircularProgress size={32} />
            </section>
        )
    }

    if (demand === null) {
        return <Typography>Demand not found.</Typography>
    }
    console.log(demand);
    return (
        <section style={{
            background: 'linear-gradient(to bottom, #d2d2d2, transparent)', color: 'white',
            paddingTop: windowWidth > 960 ? 155 : 80,
            paddingRight: 10
        }}>

            <Grid container style={{ paddingRight: 25 }}>
                <Grid item xs={12} sm={12} md={2}>

                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ padding: 5 }}>
                    <Button variant="outlined" startIcon={<ArrowLeft />} style={{ margin: 10, marginLeft: 0 }} onClick={() => history.push("/demands-available")}>Back to Available Demands</Button>
                    <Card>
                        <CardHeader title={`Demand for ${demand.category}${demand.subcategory ? ` - ${demand.subcategory}` : ''}`} />
                        <CardContent>
                            <InlinePanel style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                <Chip icon={<MyLocation />}
                                    label={`${demand.city}, ${demand.state}`} />
                                <Chip icon={<AccessTime />}
                                    label={`${demand.isFullTime ? 'Full Time' : 'Part Time'}`} />
                                <Chip icon={<Timelapse />}
                                    label={`Posted ${getTimeSince(new Date(demand.creationDate))} ago`} />
                            </InlinePanel>
                            <Typography variant="subtitle1" style={{ marginTop: 10, fontWeight: 600 }}>Description</Typography>
                            <Typography variant="body1">{demand.description}</Typography>
                            <Typography variant="subtitle1" style={{ marginTop: 10, fontWeight: 600 }}>Requirements</Typography>
                            <List>
                                {
                                    demand.requirements.map((req) => {
                                        return (
                                            <ListItem key={`req-${req.toLowerCase()}`}>{req}</ListItem>
                                        );
                                    })
                                }
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ padding: 5, marginTop: 55 }}>
                    <ThemeProvider theme={darkTheme}>
                        <Card color="primary">
                            <CardContent>
                                {/* <TextIcon iconName="monetization_on">
                                    x{demand.price}
                                </TextIcon>*/}
                                <TextIcon iconName="monetization_on">
                                    Free
                                </TextIcon>
                                <Typography variant="subtitle2">Customer's contact</Typography>
                                <InlinePanel style={{ width: '100%', flexDirection: 'space-between', flexWrap: 'wrap' }}>
                                    <TextIcon iconName="person" containerStyle={{ flexGrow: 1 }}>
                                        {demand.customerName}
                                    </TextIcon>
                                    <TextIcon iconName="phone" containerStyle={{ flexGrow: 1 }}>
                                        {demand.customerContact}
                                    </TextIcon>
                                </InlinePanel>
                                {
                                    demand.isUnlocked ?
                                        <Typography variant="body1" style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>
                                            Unlocked demand
                                        </Typography>
                                        :
                                        <Button fullWidth variant="contained" color="secondary" disabled={isUnlockingDemand}
                                            style={{ marginTop: 10 }}
                                            onClick={() => unlockDemand(demand.id, setShowLoginModal)}>
                                            {
                                                isUnlockingDemand ?
                                                    <><CircularProgress size={16} color="secondary" style={{ marginRight: 10 }} /> Unlocking demand...</>
                                                    :
                                                    //<>Unlock this demand - x{demand.price} Jobcoins</>
                                                    <>Unlock this demand - free trial period - indefinite time</>
                                            }

                                        </Button>
                                        
                                }
                            </CardContent>
                        </Card>
                    </ThemeProvider>

                </Grid>
            </Grid>
            {showLoginModal && <ModalLogin show={showLoginModal} handleClose={() => setShowLoginModal(false)} />}

        </section >

    )
}
import React, { useState } from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';

const CreditCardField = (props) => {
    const { inputRef, ...other } = props;
    const mask = [
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/
    ];

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide
            mask={mask}
            placeholderChar={'0'}
            showMask
        />
    )
};

const ExpiryField = (props) => {
    const { inputRef, ...other } = props;
    const mask = [
        /\d/, /\d/, '/', /\d/, /\d/];

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide
            mask={mask}
            placeholderChar={'0'}
            showMask
        />
    )
};

const CvcField = (props) => {
    const { inputRef, ...other } = props;
    const mask = [
        /\d/, /\d/, /\d/];

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide
            mask={mask}
            placeholderChar={'0'}
            showMask
        />
    )
};


export const CreditCardForm = ({ cvc, cardNumber, expiry, fullName, setCardNumber, setCvc, setExpiry, setFullName }) => {

    const [focus, setFocus] = useState('');

    return (
        <div>
            <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={fullName}
                number={cardNumber}
            />
            <Grid container>
                <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="number"
                        value={cardNumber}
                        label="Card Number"
                        style={{ textTransform: 'capitalize' }}
                        onChange={({ target }) => setCardNumber(target.value)}
                        onFocus={({ target }) => setFocus(target.name)}
                        InputProps={{ inputComponent: CreditCardField }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="name"
                        value={fullName}
                        label="Owner Name"
                        onChange={({ target }) => setFullName(target.value)}
                        onFocus={({ target }) => setFocus(target.name)}
                    />
                </Grid>
                <Grid item xs={12} sm={8} style={{ padding: 5 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="expiry"
                        value={expiry}
                        label="Valid Thru"
                        onChange={({ target }) => setExpiry(target.value)}
                        onFocus={({ target }) => setFocus(target.name)}
                        InputProps={{ inputComponent: ExpiryField }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} style={{ padding: 5 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="cvc"
                        value={cvc}
                        label="CVC"
                        onChange={({ target }) => setCvc(target.value)}
                        onFocus={({ target }) => setFocus(target.name)}
                        InputProps={{ inputComponent: CvcField }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
import React, { useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@material-ui/core';
import { CreditCardForm } from '../../components/credit-card-form';
import { getCreditCardToken, purchaseJobcoinsPacket } from '../../api/services/jobcoinsServices';

function wait(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const ModalJobcoinsPayment = ({ totalCoins, price, idJobcoinsPacket, show, onClose }) => {
    const [fullName, setFullName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [isPurchasing, setIsPurchasing] = useState(false);

    async function purchasePacket() {
        setIsPurchasing(true);
        const success = await purchaseJobcoinsPacket(cardNumber, expiry.split('/')[0], expiry.split('/')[1], cvc, idJobcoinsPacket);
        setIsPurchasing(false);
        if (success) {
            onClose();
        }
    }

    return (
        <Dialog maxWidth="sm" open={show} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>You are purchasing a x{totalCoins} JobCoins Packet</DialogTitle>
            <DialogContent>
                <Typography>Please, provide your credit card information</Typography>
                <CreditCardForm cardNumber={cardNumber}
                    cvc={cvc}
                    expiry={expiry}
                    fullName={fullName}
                    setCardNumber={setCardNumber}
                    setFullName={setFullName}
                    setCvc={setCvc}
                    setExpiry={setExpiry} />
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button style={{ margin: 5 }} variant="contained" color="secondary" disabled={isPurchasing} onClick={purchasePacket}>
                        {isPurchasing && <CircularProgress size={16} style={{ marginRight: 10 }} />}
                        {isPurchasing ? "Purchasing" : `Purchase for US$ ${price.toFixed(2)}`}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
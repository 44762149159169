import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSnackbar from './BaseSnackbar';

class DangerSnackbar extends Component {
    render() {
        const { message, showDanger } = this.props;
        return (
            <BaseSnackbar icon={'close'} show={showDanger} message={message} color="#c62828" />
        );
    }
}

const mapStateToProps = ({ snackbars }) => ({
    message: snackbars.message,
    showDanger: snackbars.showType.toLowerCase() === 'danger'
});


export default connect(mapStateToProps)(DangerSnackbar);
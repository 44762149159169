import { Container, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWindowDimensions from './0 - Shared/hooks/useWindowDimensions';
import { NotFound } from './0 - Shared/NotFound';
import AnswerQuestions from './Category/AnswerQuestions';
import { ChooseSubcategories } from './Category/ChooseSubcategories';
import ModalUserData from './Category/ModalUserData';
import { NeedServices } from './Category/NeedServices';


const CategoryScreen = props => {
    const { allCategories } = props;
    const { category } = useParams();
    const [showUserData, setShowModal] = useState(false);
    const { width: windowWidth } = useWindowDimensions();

    let macrocategory = null;
    let isMacrocategory = false;

    if (allCategories.filter(cat => cat.name === category).length > 0) {
        macrocategory = allCategories.filter(cat => cat.name === category)[0];
        isMacrocategory = true;
    } else {
        for (let cat of allCategories) {
            if (Boolean(macrocategory)) {
                break;
            }

            const subcatsContaining = cat.subcategories.filter(subcat => subcat.name === category);
            if (subcatsContaining.length > 0) {
                macrocategory = cat;
                isMacrocategory = false;
            }
        }
    }
    
    if (allCategories.length <= 0) {
        return null;
    }

    if (!Boolean(macrocategory)) {
        return (<NotFound />);
    }    

    function showModalUserData() {
        setShowModal(true);
    }

    function closeModalUserData() {
        setShowModal(false);
    }

    return (
        <div style={{ marginTop: windowWidth > 960 ? 145 : 60, backgroundColor: '#f0f0f0' }}>
            <Container>
                <NeedServices category={category} macroCategory={macrocategory.name} />
                <Divider style={{ marginTop: -4 }} />
                {
                    isMacrocategory ?
                        <ChooseSubcategories subcategories={macrocategory.subcategories} category={macrocategory.name} />
                        :
                        <AnswerQuestions subcategory={category} showUserData={showModalUserData} />
                }
                <ModalUserData category={macrocategory.name} subcategory={category} show={showUserData} onOpen={showModalUserData} onClose={closeModalUserData} />
            </Container>
        </div>
    );
}

const mapStateToProps = ({ categories }) => ({
    allCategories: categories.allCategories
});

export default connect(mapStateToProps)(CategoryScreen);
import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';

class ModalAboutUs extends Component {
    _rediretTo = (url) => {
        window.location = url;
    }

    render() {
        const { show, handleClose } = this.props;
        return (
            <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>About InstaJob</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>Instajob is an application created to directly connect professionals quickly and easily with customers across the country, more than a communication channel for publicizing services, Instajob works as a channel of opportunities, Instajob has no registration fee and does not we charge any kind of commission, our mission is to connect those who need services with those who know how to do it.</Typography>
                    {/*<Typography variant="h6" style={{ textAlign: 'center', marginTop: 10 }}>Our dream</Typography>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>
                        We truly believe in bringing simple solutions to people's lives.
                        <br />
                        Our goal is to join who needs, who knows how to do.
                        <br />
                        We are sure that helping professionals get more jobs and opportunities, increasing their clients and earnings, we help our economy grow.
                        Providing people with a better quality of life and quality services, safely and quickly, will change the world.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>InstaJob, the solution you've always been looking for!</Typography>
        <Typography variant="body2" style={{ textAlign: 'center', fontWeight: 'bold' }}>Welcome, we are InstaJob.</Typography>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ModalAboutUs;
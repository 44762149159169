export const STORE_DEMANDS = 'STORE_DEMANDS';
export const FILTER_DEMANDS = 'FILTER_DEMANDS';
export const SAVE_DEMAND_REQUIREMENTS = 'SAVE_DEMAND_REQUIREMENTS';
export const SAVE_DEMAND_DATA = 'SAVE_DEMAND_DATA';
export const SAVE_CONFIRMATION_CODE = 'SAVE_CONFIRMATION_CODE';
export const CLEAN_CONFIRMATION_CODE = 'CLEAN_CONFIRMATION_CODE';
export const SET_REQUESTING_COMMON_DEMANDS = 'SET_REQUESTING_COMMON_DEMANDS';
export const SET_REQUESTING_OPEN_DEMANDS = 'SET_REQUESTING_OPEN_DEMANDS';
export const SET_REQUESTING_GOLDEN_DEMANDS = 'SET_REQUESTING_GOLDEN_DEMANDS';
export const STORE_COMMON_DEMANDS_PAGED = 'STORE_COMMON_DEMANDS_PAGED';
export const STORE_OPEN_DEMANDS_PAGED = 'STORE_OPEN_DEMANDS_PAGED';
export const STORE_GOLDEN_DEMANDS_PAGED = 'STORE_GOLDEN_DEMANDS_PAGED';

export const SET_REQUESTING_DEMAND_BY_ID = 'SET_REQUESTING_DEMAND_BY_ID';
export const STORE_DEMAND_BY_ID = 'STORE_DEMAND_BY_ID';

export const SET_REQUESTING_UNLOCK_DEMAND = 'SET_REQUESTING_UNLOCK_DEMAND';
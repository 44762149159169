import { STORE_CATEGORIES } from "./categoriesActionTypes";

const initialState = {
    allCategories: []
};

export const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_CATEGORIES:
            return {
                ...state,
                allCategories: action.categories
            };
        default:
            return state;
    }
};
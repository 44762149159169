import React, { useMemo, Fragment } from 'react';
import { Grid, ButtonGroup, Button, Typography } from '@material-ui/core';
import { DemandCard } from '../DemandCard';
import { LoadingDemandCard } from '../LoadingDemandCard';
import { getTimeSince } from '../../../helpers';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';

export const DemandList = ({ pagedList, isLoading, onPageChange }) => {
    const pagination = useMemo(() => {
        if (pagedList) {
            let pagination = [];
            const initialPage = (pagedList.pageIndex - 2) <= 0 ? 0 : (pagedList.pageIndex - 2);
            const finalPage = (pagedList.pageIndex + 3) <= pagedList.totalPages ? pagedList.pageIndex + 3 : pagedList.totalPages;
            for (let i = initialPage; i < finalPage; i++) {
                pagination.push(
                    <Button key={i} variant={i === pagedList.pageIndex ? "contained" : "outlined"} color="secondary"
                        onClick={() => {
                            onPageChange(i);
                        }}>{i + 1}</Button>
                );
            }
            return pagination;
        }
    }, [pagedList, onPageChange]);

    const loadingCards = useMemo(() => {
        const cards = [];
        for (let index = 0; index < 6; index++) {
            cards.push(
                <Grid item xs={12} sm={6} lg={4} key={index}>
                    <LoadingDemandCard />
                </Grid>
            );
        }
        return cards;
    })

    return (
        <Grid container>
            {
                isLoading ?
                    loadingCards
                    :
                    pagedList && pagedList.items.length <= 0 ?
                        <Typography color="primary" component="small">No demands found...</Typography>
                        :
                        pagedList && pagedList.items.map((demand) => {
                            return (
                                <Grid item xs={12} sm={6} lg={4}>
                                    <DemandCard key={demand.id}
                                        id={demand.id}
                                        category={Boolean(demand.subcategory) ? demand.subcategory : demand.category}
                                        city={demand.city}
                                        contactNumber={demand.customerContact}
                                        description={demand.description}
                                        hoursAgo={getTimeSince(new Date(demand.creationDate))}
                                        isFullTime={demand.isFullTime}
                                        stateCode={demand.stateCode}
                                        isGoldenDemand={demand.isGoldenDemand}
                                        isActive={demand.isActive}
                                    />
                                </Grid>
                            )
                        })
            }
            <Grid item xs={12} style={{ padding: 10, textAlign: 'right' }}>
                {
                    isLoading ?
                        null
                        :
                        <ButtonGroup color="secondary" aria-label="outlined primary button group">
                            <Button disabled={pagedList ? !pagedList.hasPreviousPage : true} onClick={() => {
                                if (pagedList) {
                                    onPageChange(pagedList.pageIndex - 1);
                                }
                            }}><ArrowLeft /></Button>
                            {pagination}
                            <Button disabled={pagedList ? !pagedList.hasNextPage : true}
                                onClick={() => {
                                    if (pagedList) {
                                        onPageChange(pagedList.pageIndex + 1);
                                    }
                                }}><ArrowRight /></Button>
                        </ButtonGroup>
                }
            </Grid>
        </Grid>
    )
}
import { useCallback } from 'react';
import { changeStatus } from '../../../api/services/demandsServices';

export const useDemandStatus = () => {
    const changeDemandStatus = useCallback(async (idDemand, newStatus) => {
      try {
        await changeStatus(idDemand, newStatus);
        // Realize qualquer ação adicional necessária após a alteração do status da demanda
      } catch (error) {
        // Lide com erros aqui, se necessário
      }
    }, []);
  
    return { changeDemandStatus };
  };
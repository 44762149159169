import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CircularProgress, Typography } from '@material-ui/core';
import PasswordField from '../../components/password-field/PasswordField';
import { login, isFirstTimeInPortal, changePassword } from '../../api/services/authServices';

const steps = ["email", "password", "setPassword"];

class ModalLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            isLogingIn: false,
            actualStep: steps[0]
        }

        this.setEmail = this.setEmail.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setConfirmPassword = this.setConfirmPassword.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
        this.tryChangePassword = this.tryChangePassword.bind(this);
        this._getStep = this._getStep.bind(this);
        this._goToNextStep = this._goToNextStep.bind(this);
        this._verifyEmail = this._verifyEmail.bind(this);
    }

    setPassword({ target }) {
        this.setState({
            password: target.value
        });
    }

    setConfirmPassword({ target }) {
        this.setState({
            confirmPassword: target.value
        });
    }

    setEmail({ target }) {
        this.setState({
            email: target.value
        });

    }

    async tryLogin() {
        this.setState({
            isLogingIn: true
        });
        const { email, password } = this.state;
        try {
            await login(email, password, false);
            this.props.handleClose();
            //window.location.reload();
        } catch (e) { }

        this.setState({
            isLogingIn: false
        });
    }

    async tryChangePassword() {
        this.setState({
            isLogingIn: true
        });
        const { email, password, confirmPassword } = this.state;

        try {
            await changePassword(email, password, confirmPassword);
            this.props.handleClose();
        } catch (e) { }

        this.setState({
            isLogingIn: false
        });
    }

    _getStep = (step) => {
        const { email, password, confirmPassword } = this.state;
        switch (step) {
            case steps[0]:
                return (<StepEmail email={email} onEmailChange={this.setEmail} />);
            case steps[1]:
                return (<StepPassword email={email} password={password} onPasswordChange={this.setPassword} />);
            case steps[2]:
                return (<StepChangePassword email={email} password={password} confirmPassword={confirmPassword} onPasswordChange={this.setPassword} onConfirmPasswordChange={this.setConfirmPassword} />);
            default:
                return null;
        }
    }

    _goToNextStep = () => {
        const index = steps.indexOf(this.state.actualStep);
        if (index - 1 >= steps.length) {
            this.tryLogin();
        } else {
            switch (this.state.actualStep) {
                case 'email':
                    this._verifyEmail();
                    break;
                case 'password':
                    this.tryLogin();
                    break;
                case 'setPassword':
                    this.tryChangePassword();
                    break;
                default:
                    break;
            }
        }
    }

    _verifyEmail = async () => {
        const isFirstTime = await isFirstTimeInPortal(this.state.email);
        if (!isFirstTime) {
            this.setState({
                actualStep: steps[1]
            });
        } else {
            this.setState({
                actualStep: steps[2]
            });
        }
    }

    render() {
        const { show, handleClose } = this.props;
        const { isLogingIn, actualStep } = this.state;

        const Step = this._getStep(actualStep);

        return (
            <div>
                <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Welcome to InstaJob!</DialogTitle>
                    <DialogContent>
                        {Step}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="default">Cancel</Button>
                        <Button onClick={this._goToNextStep} variant="contained" color="secondary" disabled={isLogingIn}>
                            {actualStep === 'password' ? "Login" : actualStep === 'setPassword' ? "Define Password" : "Continue"} {isLogingIn && <CircularProgress color="inherit" size={20} style={{ marginLeft: 15 }} />}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const StepEmail = ({ email, onEmailChange }) => {
    return (
        <>
            <Typography variant="body1">Please, write your e-mail address.</Typography>
            <br />
            <TextField
                autoFocus
                id="email"
                variant="outlined"
                label="Email Address"
                type="email"
                onChange={onEmailChange}
                value={email}
                fullWidth
            />
        </>
    );
}

const StepPassword = ({ password, onPasswordChange }) => {
    return (
        <>
            <Typography variant="body1">Now, enter your password.</Typography>
            <br />
            <PasswordField
                margin="normal"
                id="password"
                variant="outlined"
                label="Password"
                onChange={onPasswordChange}
                value={password}
                fullWidth={true}
            />
        </>
    )
}

const StepChangePassword = ({ email, password, onPasswordChange, confirmPassword, onConfirmPasswordChange }) => {
    return (
        <>
            <Typography variant="body1">Hello {email}! It seems that it's your first time logging in our Website. Please, set a password for you.</Typography>
            <Typography variant="subtitle2">You only will need this password to sign in our Website.</Typography>
            <PasswordField
                margin="normal"
                id="password"
                variant="outlined"
                label="Password"
                onChange={onPasswordChange}
                value={password}
                fullWidth
            />
            <PasswordField
                margin="normal"
                id="confirm-password"
                variant="outlined"
                label="Confirm your Password"
                onChange={onConfirmPasswordChange}
                value={confirmPassword}
                fullWidth
            />
        </>
    )
}

export default ModalLogin;
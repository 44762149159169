import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, TextField, Grid, Hidden, CircularProgress } from '@material-ui/core';
import PhoneNumberField from '../../components/phone-number-field/PhoneNumberField';
import beWorker from '../../assets/background-be-worker.jpg';
import { sendNotifications } from '../../api/services/authServices';

class ModalAddDemand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            phoneNumber: '',
            isRequesting: false
        }
    }

    onEmailChange = ({ target }) => {
        this.setState({
            email: target.value
        });
    }

    onPhoneNumberChange = ({ target }) => {
        this.setState({
            phoneNumber: target.value
        });
    }

    _rediretTo = (url) => {
        window.location = url;
    }

    _sendNotifications = async () => {
        const { email, phoneNumber } = this.state;
        this.setState({
            isRequesting: true
        });
        try {
            await sendNotifications(email, phoneNumber);
        } catch (e) {

        }
        this.setState({
            isRequesting: false
        });

        this.props.handleClose();
    }

    render() {
        const { show, handleClose } = this.props;
        const { email, phoneNumber } = this.state;

        return (
            <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Get more customers directly on your phone</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" style={{ marginBottom: 15 }}>Receive free demands and pay only for their contact number.</Typography>
                    <Grid container>
                        <Grid item sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
                            <TextField
                                autoFocus
                                id="email"
                                variant="outlined"
                                label="Email Address"
                                type="email"
                                onChange={this.onEmailChange}
                                value={email}
                                fullWidth
                            />
                            <TextField
                                margin="normal"
                                id="name"
                                variant="outlined"
                                label="Phone Number"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                InputProps={{ inputComponent: PhoneNumberField }}
                                onChange={this.onPhoneNumberChange}
                                value={phoneNumber}
                                fullWidth
                            />
                        </Grid>
                        {/*<Grid item sm={6} xs={12}>
                            <Hidden xsDown>
                                <img src={beWorker} alt="Register now" style={{ width: '100%' }} />
                            </Hidden>
        </Grid>*/}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this._sendNotifications}  disabled={this.state.isRequesting}>
                        Register my services
                        {this.state.isRequesting && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ModalAddDemand;
import React from 'react';
import { styled } from '@material-ui/styles';
import { Icon, Typography, Box } from '@material-ui/core';

export const InlinePanel = styled(Box)({
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
});

export const TextIcon = ({ iconName, children, containerStyle }) => {
    return (
        <InlinePanel style={containerStyle}>
            <Icon style={{ marginRight: 10 }}>{iconName}</Icon>
            <Typography variant="body1">{children}</Typography>
        </InlinePanel>
    )
}
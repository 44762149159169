import React, { useState, useEffect } from 'react';
import { Hidden, Drawer, CircularProgress, Typography } from '@material-ui/core';
import { Route, Switch as SwitchRouter } from 'react-router-dom';

import { getAllCategories } from '../../api/services/categoriesServices';
import { getAllDemands } from '../../api/services/demandsServices';
import { hasLoggedUser } from '../../api/services/authServices';

import Footer from '../0 - Shared/Footer';
import AppTopBar from '../0 - Shared/AppTopBar';
import CategoryList from '../0 - Shared/CategoryList';
import LeftDrawerItems from '../0 - Shared/LeftDrawerItems';
import ModalUnlockDemand from '../Modals/ModalUnlockDemand';
import ModalBeAWorker from '../Modals/ModalBeAWorker';
import ModalAddDemand from '../Modals/ModalAddDemand';
import ModalAboutUs from '../Modals/ModalAboutUs';
import ModalHowWorks from '../Modals/ModalHowWorks';
import HomeScreen from '../HomeScreen';
import CategoryScreen from '../CategoryScreen';
import { NotFound } from '../0 - Shared/NotFound';
import ModalLogin from '../Modals/ModalLogin';
import { BuyJobcoinsScreen } from '../BuyJobcoins';
import { DemandsAvailableScreen } from '../DemandsAvailable';
import { DemandsRequestAvailableScreen } from '../DemandsRequest';
import { DemandsAvailableScreenAdd } from '../DemandsAvailableAdd';
import { InterestedCategoriesAdd } from '../Interested';
import { DemandDetailsScreen } from '../DemandDetails';
import { useScrollToTop } from './hooks';
import { Privacy } from '../Privacy/Privacy';
import { Terms } from '../Terms/Terms';

export const PrincipalScreen = () => {
    const [showLeftDrawer, setShowLeftDrawer] = useState(false);
    const [showBeWorkerModal, setShowBeWorkerModal] = useState(false);
    const [showAddDemandModal, setShowAddDemandModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showUnlockModal, setShowUnlockModal] = useState(false);
    const [showAboutUsModal, setShowAboutUsModal] = useState(false);
    const [showHowWorksModal, setShowHowWorksModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!isLoading && !loaded) {
            setIsLoading(true);
            Promise.all([getAllCategories(), getAllDemands(), hasLoggedUser()])
                .then(() => {
                    setLoaded(true);
                });
        }
    });

    useScrollToTop();

    if (!loaded) {
        return (
            <div style={{ display: 'flex', height: '100vh', flex: 1, flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
                <Typography variant="body1">Loading all data... Don't worry, that's fast!</Typography>
            </div>
        );
    }
    return (
        <>
            <header style={{ position: 'fixed', zIndex: 999 }}>
                <AppTopBar onMenuClick={() => setShowLeftDrawer(true)}
                    onBeWorkerClick={() => setShowBeWorkerModal(true)}
                    onHowWorksClick={() => setShowHowWorksModal(true)}
                    onLoginClick={() => setShowLoginModal(true)} />
                <Hidden smDown>
                    <CategoryList />
                </Hidden>
                <Drawer open={showLeftDrawer} onClose={() => setShowLeftDrawer(false)}>
                    <LeftDrawerItems onClose={() => setShowLeftDrawer(false)}
                        onBeWorkerClick={() => setShowBeWorkerModal(true)}
                        onHowWorksClick={() => setShowHowWorksModal(true)} />
                </Drawer>
            </header>
            <main style={{ overflowX: 'hidden', width: '100vw', margin: 0 }}>
                <SwitchRouter>
                    <Route path="/" exact component={() => <HomeScreen toggleModalUnlock={() => setShowUnlockModal(!showUnlockModal)}
                        toggleModalBeWorker={() => setShowBeWorkerModal(!setShowBeWorkerModal)} />} />
                    <Route path="/jobcoins" exact component={BuyJobcoinsScreen} />
                    <Route path="/demands-available" exact component={DemandsAvailableScreen} />
                    <Route path="/my-open-demands" exact component={DemandsRequestAvailableScreen} />
                    <Route path="/demands-available-add" exact component={DemandsAvailableScreenAdd} />
                    <Route path="/interested-categories" exact component={InterestedCategoriesAdd} />
                    <Route path="/demand/:idDemand" exact component={DemandDetailsScreen} />
                    <Route path="/privacy" exact component={Privacy} />
                    <Route path="/terms" exact component={Terms} />
                    <Route path="/:category">
                        <CategoryScreen />
                    </Route>
                    <Route path="*" exact component={() => <NotFound />} />
                </SwitchRouter>
                <ModalLogin show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
                <ModalUnlockDemand show={showUnlockModal} handleClose={() => setShowUnlockModal(false)} />
                <ModalBeAWorker show={showBeWorkerModal} handleClose={() => setShowBeWorkerModal(false)} />
                <ModalAboutUs show={showAboutUsModal} handleClose={() => setShowAboutUsModal(false)} />
                <ModalHowWorks show={showHowWorksModal} handleClose={() => setShowHowWorksModal(false)} />
            </main>
            <footer style={{ paddingTop: 20, marginTop: 20, background: '#122b4e' }}>
                <Footer onAboutUsClicked={() => setShowAboutUsModal(true)} />
            </footer>
        </>
    )
}
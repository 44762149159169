import React, { Component } from 'react';
import { Paper, CircularProgress, Popover, Typography, Button, Container } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger, bindHover } from 'material-ui-popup-state';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CategoryItem } from './CategoryItem';
import { theme } from '../../theme';


const CategoryList = props => {
    const { categories } = props;
    const { push: redirectTo } = useHistory();

    return (
        <Paper style={{ width: '98.8vw', paddingTop: 65, backgroundColor: '#ffffff', height: 83 }}>
            <Container style={{ display: 'flex', flex: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    categories ?
                        categories.map((cat) => {
                            return (<PopupState key={cat.id} variant="popper" popupId={"demo-popup-popover-" + cat.id}>
                                {
                                    popupState => (
                                        <div>
                                            <CategoryItem active={popupState.isOpen} icon={cat.iconPath} /*{...bindHover(popupState)}*/ {...bindTrigger(popupState)}>{cat.name}</CategoryItem>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 0,
                                                    horizontal: 'center',
                                                }}>

                                                <div style={{ padding: 10, width: '100vw', maxHeight: 200, display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                                    {
                                                        cat.subcategories ?
                                                            cat.subcategories.map((subcat) => {
                                                                return <Button key={subcat.id} onClick={() => {
                                                                    redirectTo(`/${subcat.name}`);
                                                                    popupState.close();
                                                                }} variant="text" style={{ justifyContent: 'flex-start' }}>{subcat.name}</Button>
                                                            })
                                                            :
                                                            <Typography>Doesn't have subcategories</Typography>
                                                    }
                                                </div>
                                            </Popover>
                                        </div>
                                    )
                                }
                            </PopupState>);
                        })
                        :
                        <CircularProgress color="secondary" />
                }
            </Container>
        </Paper>
    );
}

export const mapStateToProps = ({ categories }) => ({
    categories: categories.allCategories
});

export default connect(mapStateToProps)(CategoryList);
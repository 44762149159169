import { postRequest, handleErrors } from "../requests";
import { URL_BASE } from "../constants";

export async function askForConfirmationCode(phone, email) {
        const formData = new FormData();
        formData.append("phoneNumber", phone);
        formData.append("email", email);
        const payload = await postRequest(`${URL_BASE}/sms/verification`, formData, null, (error) => {
            const { status, data } = error;
            handleErrors(status, data);
        });
    return payload.data;
}
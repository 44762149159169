import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./snackbarActionTypes";

export const showSnackbar = (type, message) => ({
    type: SHOW_SNACKBAR,
    showType: type,
    message
});

export const hideSnackbar = () => ({
    type: HIDE_SNACKBAR
});
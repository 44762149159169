import { STORE_DEMANDS, FILTER_DEMANDS, SAVE_DEMAND_DATA, SAVE_DEMAND_REQUIREMENTS, SAVE_CONFIRMATION_CODE, CLEAN_CONFIRMATION_CODE, 
    SET_REQUESTING_COMMON_DEMANDS, STORE_COMMON_DEMANDS_PAGED, 
    SET_REQUESTING_OPEN_DEMANDS, STORE_OPEN_DEMANDS_PAGED, 
    SET_REQUESTING_GOLDEN_DEMANDS, STORE_GOLDEN_DEMANDS_PAGED, SET_REQUESTING_DEMAND_BY_ID, STORE_DEMAND_BY_ID, SET_REQUESTING_UNLOCK_DEMAND } from "./demandsActionTypes";

const initialState = {
    allDemands: [],
    demandsFiltered: [],
    demandAccount: {},
    commonDemandsPaged: {
        totalPages: 0,
        pageIndex: 0,
        items: [],
        hasNextPage: true,
        hasPreviousPage: true,
        totalItems: 0
    },
    isRequestingCommonDemandsPaged: false,
    goldenDemandsPaged: {
        totalPages: 0,
        pageIndex: 0,
        items: [],
        hasNextPage: true,
        hasPreviousPage: true,
        totalItems: 0
    },
    isRequestingGoldenDemandsPaged: false,
    isRequestingDemandById: false,
    demandById: null,
    isRequestingUnlockDemand: false
};

export const demandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_DEMANDS:
            return {
                ...state,
                allDemands: action.demands
            };
        case FILTER_DEMANDS:
            return {
                ...state,
                demandsFiltered: action.demands
            }
        case SAVE_DEMAND_REQUIREMENTS:
            return {
                ...state,
                demandAccount: {
                    ...state.demandAccount,
                    requirements: action.requirements,
                    isFullTime: action.isFullTime
                }
            };
        case SAVE_CONFIRMATION_CODE:
            return {
                ...state,
                demandAccount: {
                    ...state.demandAccount,
                    confirmationCode: action.code
                }
            }
        case CLEAN_CONFIRMATION_CODE:
            return {
                ...state,
                demandAccount: {
                    ...state.demandAccount,
                    confirmationCode: null
                }
            }
        case SET_REQUESTING_COMMON_DEMANDS:
            return {
                ...state,
                isRequestingCommonDemandsPaged: action.isRequesting
            };
        case STORE_COMMON_DEMANDS_PAGED:
            return {
                ...state,
                commonDemandsPaged: action.list
            };
        case SET_REQUESTING_OPEN_DEMANDS:
            return {
                ...state,
                isRequestingOpenDemandsPaged: action.isRequesting
            };
        case STORE_OPEN_DEMANDS_PAGED:
            return {
                ...state,
                openDemandsPaged: action.list
            };
        case SET_REQUESTING_GOLDEN_DEMANDS:
            return {
                ...state,
                isRequestingGoldenDemandsPaged: action.isRequesting
            };
        case STORE_GOLDEN_DEMANDS_PAGED:
            return {
                ...state,
                goldenDemandsPaged: action.list
            };
        case SET_REQUESTING_DEMAND_BY_ID:
            return {
                ...state,
                isRequestingDemandById: action.isRequesting
            };
        case STORE_DEMAND_BY_ID:
            return {
                ...state,
                demandById: action.demand
            };
        case SET_REQUESTING_UNLOCK_DEMAND:
            return {
                ...state,
                isRequestingUnlockDemand: action.isRequesting
            }
        default:
            return state;
    }
};
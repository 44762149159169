import React, { useState } from 'react'; // Importe useState
import {
  Grid,
  Container,
  Typography,
  Button,
  Icon,
} from '@material-ui/core';

import instajobApp from '../../assets/are_you_professional_hand.png';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';
import ModalBeAWorker from '../../features/Modals/ModalBeAWorker'; 

const YouAreProfessional = props => {
    const { width: windowWidth } = useWindowDimensions();
    const [showBeWorkerModal, setShowBeWorkerModal] = useState(false); // Adicione aqui

    return (
        <section>
            <Container style={{ marginTop: -25, marginBottom: windowWidth > 960 ? -4 : 20, height: windowWidth > 960 ? 524 : 'inherit' }}>
                <Grid container style={{ margin: 0, padding: windowWidth > 960 ? '0 40px' : 10 }}>
                    <Grid item sm={12} xs={12} md={6} style={{ textAlign: 'center' }}>
                        <img src={instajobApp} alt="InstaJob Mobile APP" style={{ height: windowWidth > 960 ? 520 : '250px' }} />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} style={{ textAlign: windowWidth > 960 ? 'right' : 'center', marginTop: windowWidth > 960 ? 100 : 20 }}>
                        <Typography variant="h4" color="primary" style={{ width: '100%', textAlign: windowWidth > 960 ? 'right' : 'center' }}>Are you a professional?</Typography>
                        <Typography variant="body1" style={{ width: '100%', textAlign: windowWidth > 960 ? 'right' : 'center', marginTop: 35 }}>InstaJob receives over 150,000 orders every month from its clients looking for professionals from various service categories like you.</Typography>
                        <Typography variant="body1" style={{ width: '100%', textAlign: windowWidth > 960 ? 'right' : 'center', marginTop: 20 }}>Access our app, register your services and receive service requests straight to your phone!</Typography>
                        <Button
    variant="contained"
    color="secondary"
    style={{ marginTop: 20 }}
    onClick={() => setShowBeWorkerModal(true)} // Alteração aqui
>
    Register my services <Icon style={{ marginLeft: 15 }}>edit</Icon>
</Button>                    </Grid>
                </Grid>
            </Container>
            <ModalBeAWorker
    show={showBeWorkerModal}
    handleClose={() => setShowBeWorkerModal(false)}
/>
        </section>
    );
}



export default YouAreProfessional;
import React from 'react';
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Hidden, IconButton, Icon, Container, Menu, MenuItem } from '@material-ui/core';
import { Link as LinkRouter, useHistory } from 'react-router-dom';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks'

import logo from '../../assets/instajob_logo_bottom.png';
import { decodeTokenJsonUser } from '../../helpers';
import { logout } from '../../api/services/authServices';
import { SnackbarAPI } from '../../redux/modules/snackbars/snackbarApi';

function AppTopBar({ onMenuClick, onLoginClick, onBeWorkerClick, onHowWorksClick, loggedUser }) {
    return (
        <div>
            <AppBar style={{ flexGrow: 1, backgroundColor: '#001f3e' }}>
                <Toolbar>
                    {
                        loggedUser ?
                            <LoggedLinks user={loggedUser} onMenuClick={onMenuClick} />
                            :
                            <OpenLinks onBeWorkerClick={onBeWorkerClick} onMenuClick={onMenuClick} onHowWorksClick={onHowWorksClick}
                                onLoginClick={onLoginClick} />
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

const OpenLinks = ({ onMenuClick, onBeWorkerClick, onHowWorksClick, onLoginClick }) => {
    return (
        <Container style={{ display: 'flex', alignItems: 'center' }}>
            <Hidden mdUp>
                <IconButton onClick={onMenuClick} edge="start" style={{ marginRight: 15 }} color="inherit" aria-label="menu">
                    <Icon>menu</Icon>
                </IconButton>
            </Hidden>
            <Typography variant="h6" style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <LinkRouter to="/">
                    <img src={logo} alt="InstaJob" style={{ marginRight: 20 }} />
                </LinkRouter>

                <Hidden smDown>
                    <Typography style={{ fontWeight: 'lighter', fontStyle: 'italic', fontSize: 14, marginLeft: 20 }}>Finding jobs easily</Typography>
                </Hidden>
            </Typography>
            <Hidden smDown>
                <Button variant="text" onClick={onHowWorksClick} style={{ color: 'inherit' }}>How It works?</Button>
                <Button variant="text" onClick={onLoginClick} style={{ color: 'inherit' }}>Login</Button>
                <Button variant="contained" color="secondary" style={{ marginLeft: 15 }} onClick={onBeWorkerClick}>Register now</Button>
            </Hidden>
        </Container>);
}

const LoggedLinks = ({ onMenuClick, user }) => {
    console.log('role:::' + user.role)
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
    const history = useHistory();

    return (
        <Container style={{ display: 'flex', alignItems: 'center' }}>
            <Hidden mdUp>
                <IconButton onClick={onMenuClick} edge="start" style={{ marginRight: 15 }} color="inherit" aria-label="menu">
                    <Icon>menu</Icon>
                </IconButton>
            </Hidden>
            <Typography variant="h6" style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <LinkRouter to="/">
                    <img src={logo} alt="InstaJob" style={{ marginRight: 20 }} />
                </LinkRouter>

                <Hidden smDown>
                    <Typography style={{ fontWeight: 'lighter', fontStyle: 'italic', fontSize: 14, marginLeft: 20 }}>Finding jobs easily</Typography>
                </Hidden>
            </Typography>
            <Hidden smDown>
                {/*<Typography>JobCoins: x{user.jobcoins_wallet}</Typography>*/}
                <Typography>Limited Time Promotion</Typography>
                <Button {...bindTrigger(popupState)} variant="text" style={{ marginLeft: 15, color: 'white' }}><Icon style={{ marginRight: 15 }}>person</Icon> {user.unique_name}</Button>
                <Menu {...bindMenu(popupState)}>

                    {user.role === 'WORKER' && (
                        <>
                            {/*<MenuItem onClick={() => {
                        history.push('/jobcoins');
                        popupState.close();
                    }}>Buy JobCoins Packets</MenuItem>*/}
                            <MenuItem onClick={() => {
                                history.push('/demands-available');
                                popupState.close();
                            }}>Demands Available</MenuItem>
                            <MenuItem onClick={() => {
                                history.push('/interested-categories');
                                popupState.close();
                            }}>Opportunity Preferences</MenuItem>
                        </>
                    )}

                    {(user.role === 'ADMINISTRATOR' || user.role === 'CUSTOMER') && (
                        <>
                            <MenuItem onClick={() => {
                                history.push('/demands-available-add');
                                popupState.close();
                            }}>Add Demands</MenuItem>
                            <MenuItem onClick={() => {
                                history.push('/my-open-demands');
                                popupState.close();
                            }}>My Open Demands</MenuItem>
                        </>
                    )}
                    <MenuItem onClick={logout}>Sign out</MenuItem>
                </Menu>
            </Hidden>
        </Container>
    )
}

const mapStateToProps = ({ auth }) => ({
    loggedUser: decodeTokenJsonUser(auth.loggedUser),
}
);

export default connect(mapStateToProps)(AppTopBar);
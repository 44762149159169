import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';
import { Container, Typography, Card, CardHeader, CardContent, CircularProgress, List, Avatar, ListItemAvatar, ListItem, ListItemText } from '@material-ui/core';
import { AttachMoney as MoneyIcon } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { getAllJobcoinsPackagesAvailable } from '../../api/services/jobcoinsServices';
import { ModalJobcoinsPayment } from '../Modals/ModalJobcoinsPayment';
import { Redirect } from 'react-router-dom';

export const BuyJobcoinsScreen = () => {
    const { width: windowWidth } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(true);
    const { packets } = useSelector(state => ({ packets: state.jobcoins.allJobcoinsPackets }))
    const [selectedPacket, setSelectedPacket] = useState(null);
    const isUserLoggedIn = useSelector(states => states.auth.loggedUser !== null)

    useEffect(() => {
        if (isLoading) {
            getAllJobcoinsPackagesAvailable()
                .then(() => {
                    setIsLoading(false);
                });
        }
    }, [isLoading])

    if (!isUserLoggedIn) {
        return <Redirect to="/" />
    }

    return (
        <section style={{
            background: 'linear-gradient(to bottom, #d2d2d2, transparent)', color: 'white',
            padding: windowWidth > 960 ? 155 : 0
        }}>
            <Container>
                <Card>
                    <CardHeader style={{ color: '#727272', textAlign: 'center' }} title="Buy JobCoins" />
                    <CardContent>
                        <Typography variant="subtitle1">Choose a JobCoins Package to purchase:</Typography>
                        {isLoading ?
                            <CircularProgress />
                            :
                            <List>
                                {
                                    packets.map(packet =>
                                        (
                                            <ListItem button key={packet.id} onClick={() => {
                                                setSelectedPacket(packet);
                                            }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <MoneyIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={`x${packet.totalCoins} JobCoins Package - Costs US$ ${packet.price}`} secondary={packet.bonusCoins > 0 && `Bonus coins: x${packet.bonusCoins} (Total: x${packet.bonusCoins + packet.totalCoins} JobCoins)`} />
                                            </ListItem>
                                        )
                                    )
                                }
                            </List>
                        }
                    </CardContent>
                </Card>
            </Container>
            <ModalJobcoinsPayment price={selectedPacket ? selectedPacket.price : 0}
                idJobcoinsPacket={selectedPacket ? selectedPacket.id : ""}
                show={Boolean(selectedPacket)}
                totalCoins={selectedPacket ? selectedPacket.totalCoins : 0}
                onClose={() => {
                    setSelectedPacket(null);
                }}
            />
        </section>
    )
}
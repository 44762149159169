import decoder from 'jwt-decode';

export function distinct(array, propertyName) {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (!map.has(item[propertyName])) {
            map.set(item[propertyName], true);
            result.push(item);
        }
    }

    return result;
}

export function decodeTokenJsonUser(token) {
    if (!Boolean(token)) {
        return null;
    }

    const decodedToken = decoder(token);
    const json = {};
    for (let prop in decodedToken) {
        const splitProp = prop.split('/');
        Object.defineProperty(json, splitProp[splitProp.length - 1], {
            writable: true,
            enumerable: false,
            configurable: true,
            value: decodedToken[prop]
        });
    }

    return json;
}

export function getTimeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);

    if (interval > 1) {
        return interval + " months";
    }
    interval = Math.floor(seconds / 86400);

    if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);

    if (interval > 1) {
        return interval + " hours";
    }
    interval = Math.floor(seconds / 60);

    if (interval > 1) {
        return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}
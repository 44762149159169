import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { categoriesReducer } from './modules/categories/categoriesReducer';
import { demandsReducer } from './modules/demands/demandsReducer';
import { filterReducer } from './modules/filter/filterReducer';
import { snackbarReducer } from './modules/snackbars/snackbarReducer';
import { authReducer } from './modules/auth/authReducer';
import { jobcoinsReducer } from './modules/jobcoins/jobcoinsReducer';

export default (history) => combineReducers({
    categories: categoriesReducer,
    demands: demandsReducer,
    filter: filterReducer,
    snackbars: snackbarReducer,
    auth: authReducer,
    jobcoins: jobcoinsReducer,
    router: connectRouter(history)
});
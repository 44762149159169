import { STORE_CATEGORIES } from "./categoriesActionTypes";

export const storeCategories = (categories) => {
    return dispatch => {
        dispatch({
            type: STORE_CATEGORIES,
            categories
        })
    };
};

import { STORE_DEMANDS, FILTER_DEMANDS, SAVE_DEMAND_DATA, SAVE_DEMAND_REQUIREMENTS, SAVE_CONFIRMATION_CODE, CLEAN_CONFIRMATION_CODE, STORE_GOLDEN_DEMANDS_PAGED, SET_REQUESTING_GOLDEN_DEMANDS, SET_REQUESTING_COMMON_DEMANDS, STORE_COMMON_DEMANDS_PAGED, 
    SET_REQUESTING_OPEN_DEMANDS, STORE_OPEN_DEMANDS_PAGED,
    SET_REQUESTING_DEMAND_BY_ID, STORE_DEMAND_BY_ID, SET_REQUESTING_UNLOCK_DEMAND } from "./demandsActionTypes";

export const storeDemands = (demands) => {
    return dispatch => {
        dispatch({
            type: STORE_DEMANDS,
            demands
        });
    };
};

export const storeFilteredDemands = (demands) => {
    return dispatch => {
        dispatch({
            type: FILTER_DEMANDS,
            demands
        });
    };
};

export const saveDemandData = (demand) => {
    return dispatch => {
        dispatch({
            type: SAVE_DEMAND_DATA,
            demand
        });
    };
};


export const saveDemandRequirements = (requirements, isFullTime) => {
    return dispatch => {
        dispatch({
            type: SAVE_DEMAND_REQUIREMENTS,
            requirements,
            isFullTime
        });
    };
};

export const saveConfirmationCode = (code) => {
    return dispatch => {
        dispatch({
            type: SAVE_CONFIRMATION_CODE,
            code
        });
    };
};

export const cleanConfirmationCode = () => {
    return dispatch => {
        dispatch({
            type: CLEAN_CONFIRMATION_CODE,
        });
    };
};

export const setRequestingGoldenDemands = (isRequesting) => {
    return dispatch => {
        dispatch({
            type: SET_REQUESTING_GOLDEN_DEMANDS,
            isRequesting
        });
    }
}

export const storeGoldenDemandsPaged = (list) => {
    return dispatch => {
        dispatch({
            type: STORE_GOLDEN_DEMANDS_PAGED,
            list
        })
    }
}

export const setRequestingCommonDemands = (isRequesting) => {
    return dispatch => {
        dispatch({
            type: SET_REQUESTING_COMMON_DEMANDS,
            isRequesting
        });
    }
}

export const storeCommonDemandsPaged = (list) => {
    return dispatch => {
        dispatch({
            type: STORE_COMMON_DEMANDS_PAGED,
            list
        })
    }
}

export const setRequestingOpenDemands = (isRequesting) => {
    return dispatch => {
        dispatch({
            type: SET_REQUESTING_OPEN_DEMANDS,
            isRequesting
        });
    }
}

export const storeOpenDemandsPaged = (list) => {
    return dispatch => {
        dispatch({
            type: STORE_OPEN_DEMANDS_PAGED,
            list
        })
    }
}

export const setRequestingDemandById = (isRequesting) => {
    return dispatch => {
        dispatch({
            type: SET_REQUESTING_DEMAND_BY_ID,
            isRequesting
        })
    }
}

export const storeDemandById = (demand) => {
    return dispatch => {
        dispatch({
            type: STORE_DEMAND_BY_ID,
            demand
        })
    }
}

export const setRequestingUnlockDemand = (isRequesting) => {
    return dispatch => {
        dispatch({
            type: SET_REQUESTING_UNLOCK_DEMAND,
            isRequesting
        });
    }
}
import axios from "axios";
import { URL_BASE } from "../constants";
import store from "../../redux/globalStore";

export async function getUserSubcategories() {
  try {
    const token = store.getState().auth.loggedUser;
    console.log("TOKEN: " + token);
    const response = await axios.get(`${URL_BASE}/account/interested-categories`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch user subcategories");
  }
}

export async function createUserSubcategory(subcategory) {
  try {
    const token = store.getState().auth.loggedUser;
    await axios.put(`${URL_BASE}/account/interested-categories`, subcategory, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create user subcategory");
  }
}
import React, { Component } from 'react';
import { Typography, Container, Grid } from '@material-ui/core';

import tellWhatYouNeed from '../../assets/what_is_instajob_01.png';
import rateProfessionals from '../../assets/what_is_instajob_02.png';
import dealDirectly from '../../assets/what_is_instajob_03.png';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

const About = props => {
    const { width: windowWidth } = useWindowDimensions();

    return (
        <section style={{
            backgroundColor: '#ee2f4a', color: 'white', height: windowWidth > 960 ? 500 : 'inherit',
            padding: windowWidth > 960 ? 0 : '50px 0'
        }}>
            <Container style={{
                textAlign: 'center', width: '70%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography variant="h4" color="inherit">What is InstaJob?</Typography>
                <article>
                    <Typography variant="body2" color="inherit" style={{ marginTop: 15, marginBottom: 15 }}>
                        InstaJob is the best services hiring platform in the United States. We connect professionals of the entire country with people requesting a quality, accessible and quick service for any type of demand
                    </Typography>
                </article>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <Instruction source={tellWhatYouNeed} alternative="Make a demand">Tell us what you need. It's fast and free!</Instruction>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Instruction source={rateProfessionals} alternative="Get budgets">Rated professionals will contact you quickly.</Instruction>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Instruction source={dealDirectly} alternative="Choose the best">Deal directly with them. It's easier than ever!</Instruction>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

const Instruction = (props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 200 }}>
            <img src={props.source} alt={props.alternative} style={{ width: 147, height: 142 }} />
            <Typography variant="body1" color="inherit">{props.children}</Typography>
        </div>
    );
}

export default About;
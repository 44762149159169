import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Slide } from 'react-slideshow-image';
import { Container, Typography, TextField, MenuItem, Grid, createMuiTheme } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

import { distinct, getTimeSince } from '../../helpers';
import { changeCityFilter, changeStateFilter } from '../../redux/modules/filter/filterActions';
import { JobPanel } from './JobPanel';
import panelInstajob from '../../assets/panel.png';

const useStyles = makeStyles({
    root: {
        borderColor: 'white',
        color: 'white',
        backgroundColor: 'white'
    },
    label: {
        color: 'white'
    }
})

const PanelCarousel = (props) => {
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');    

    const _selectState = ({ target }) => {
        setSelectedState(target.value);

        props.changeStateFilter(target.value);

        const stateFilter = target.value;
        const { demands, cityFilter } = props;
        const cities = distinct(demands.filter(x => (stateFilter === '' || x.stateCode === stateFilter) && x.city.includes(cityFilter)).map((demand) => ({ city: demand.city, stateCode: demand.stateCode, state: demand.state })), "city");
        if (cities.length <= 0) {
            _selectCity({ target: { value: '' } });
        }
    }

    const _selectCity = ({ target }) => {
        setSelectedCity(target.value);
        props.changeCityFilter(target.value)
    }

    const { demands, stateFilter, cityFilter, onUnlockClick } = props;
    const classes = useStyles();

    const filteredDemands = demands
        .filter((demand) => {
            if (Boolean(stateFilter)) {
                return demand.stateCode.includes(stateFilter);
            }
            return true;
        })
        .filter((demand) => {
            if (Boolean(cityFilter)) {
                return demand.city.includes(cityFilter);
            }
            return true;
        })
        .filter((demand, i) => i <= 12);

    const states = distinct(demands.map((demand) => ({ city: demand.city, stateCode: demand.stateCode, state: demand.state })), "state");
    const cities = distinct(demands.filter(x => stateFilter === '' || x.stateCode === stateFilter).map((demand) => ({ city: demand.city, stateCode: demand.stateCode, state: demand.state })), "city");

    return (
        <>
            <div style={{ backgroundColor: '#2436c4', color: 'white', paddingTop: 50, paddingBottom: 100 }}>
                <Container>
                    <div style={{ marginLeft: 40, marginRight: 40 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="h3" color="inherit" align="left" style={{ width: '100%', marginBottom: 25 }}>Panel</Typography>
                                <Typography variant="body1">
                                    In InstaJob Panel you have access to various job opportunities and services, daily are posted several jobs from all cities of the United States, click, access the vacancies of interest, contact the companies and get the job of their dreams.
                                </Typography>
                                <Typography variant="h5" color="inherit" align="left" style={{ width: '100%', marginBottom: 15, marginTop: 10 }}>Professional</Typography>
                                <Typography variant="body1">
                                    If you are looking for a job stay tuned, every day we post hundreds of opportunities in your city!
                                </Typography>
                                <Typography variant="h5" color="inherit" align="left" style={{ width: '100%', marginBottom: 15, marginTop: 10 }}>Companies</Typography>
                                <Typography variant="body1">
                                    If your company is looking for a qualified professional quickly and securely, post your job offer with us, it's totally free and simple, don't waste time posting it in many different places, InstaJob offers your company a free and controlled platform. Hire a professional within minutes, the solution your business has always sought.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={panelInstajob} alt="InstaJob Mobile APP" style={{ maxWidth: '90%', }} />
                            </Grid>
                        </Grid>
                        <ThemeProvider theme={createMuiTheme({
                            palette: {
                                type: 'dark',
                                background: {
                                    default: '#fff',
                                    paper: '#fff'
                                },
                                text: {
                                    hint: "rgba(0, 0, 0, 0.38)"
                                }
                            }
                        })}>
                            <Grid container>
                                <Grid item xs={12} sm={6} style={{ padding: 10, paddingBottom: 0 }}>

                                    <TextField
                                        select
                                        fullWidth
                                        label="State"
                                        value={selectedState}
                                        onChange={_selectState}
                                        style={{ textAlign: 'left' }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem value={""} style={{ color: '#424242' }}>Select a State...</MenuItem>
                                        {states.map((cat, i) => (<MenuItem key={i} style={{ color: '#424242' }} value={cat.stateCode}>{cat.state}</MenuItem>))}
                                    </TextField>


                                </Grid>
                                <Grid item xs={12} sm={6} style={{ padding: 10, paddingBottom: 0 }}>
                                    <TextField
                                        select
                                        fullWidth
                                        label="City"
                                        value={selectedCity}
                                        onChange={_selectCity}
                                        style={{ textAlign: 'left', color: 'white' }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem value={""} style={{ color: '#424242' }}>Select a City...</MenuItem>
                                        {cities.map((cat, i) => (<MenuItem key={i} style={{ color: '#424242' }} value={cat.city}>{cat.city}</MenuItem>))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </Container>
            </div>
            <div style={{ marginTop: -75, height: 400 }}>
                <Container>
                    <Slide duration={5000} transitionDuration={200} infinite={false}>
                        {
                            filteredDemands.map((demand, i) => (
                                <JobPanel key={i}
                                    onUnlockClick={onUnlockClick}
                                    isExclusive={demand.isExclusive}
                                    category={demand.category}
                                    isFullTime={demand.isFullTime}
                                    localization={`${demand.city} - ${demand.stateCode}`}
                                    hoursAgo={getTimeSince(new Date(demand.creationDate))}
                                    description={demand.description}
                                    requirements={demand.requirements}
                                    contactNumber={demand.customerContact}
                                    id={demand.id}
                                    price={demand.price} />
                            ))
                        }
                    </Slide>
                </Container>
            </div>
        </>
    );
}


const mapStateToProps = ({ demands, filter }) => ({
    demands: demands.demandsFiltered,
    stateFilter: filter.stateFilter,
    cityFilter: filter.cityFilter
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({ changeCityFilter, changeStateFilter }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(PanelCarousel);
import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#3b4a69',
            main: '#0f233e',
            dark: '#000019',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff6973',
            main: '#ec2e48',
            dark: '#b20021',
            contrastText: '#fff',
        },        
    },    
    typography: {
        fontFamily: "Montserrat, Lato, Roboto",
        h1: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        h2: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        h3: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        h4: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        h6: {
            fontFamily: 'Montserrat'
        }
    }
});
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Snackbar, SnackbarContent, IconButton, Icon, Typography } from '@material-ui/core';

import { hideSnackbar } from '../../redux/modules/snackbars/snackbarActions';

class BaseSnackbar extends Component {
    render() {
        const { message, show, icon, color, hideSnackbar } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={show}
                autoHideDuration={6000}
                onClose={hideSnackbar}>
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    style={{ backgroundColor: color }}
                    message={
                        <Typography variant="body1" style={{ padding: 0 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Icon style={{ marginRight: 15 }}>{icon}</Icon>
                                {message}
                            </div>
                        </Typography>
                    }
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar}>
                            <Icon>close</Icon>
                        </IconButton>
                    ]}
                />
            </Snackbar>
        );
    }
}

const mapDangerActionsToProps = dispatch => (
    bindActionCreators({ hideSnackbar }, dispatch)
);

export default connect(null, mapDangerActionsToProps)(BaseSnackbar);
import React from 'react';
import { Typography, Card, CardContent, Chip, List, ListItem, CardActions, Button, Icon, Grid, Container } from "@material-ui/core"
import { Redirect } from 'react-router-dom';
import calendar from '../../assets/calendar.png';
import location from '../../assets/location.png';
import partTime from '../../assets/part_time.png';
import exclusiveIcon from '../../assets/exclusive-demand.jpeg';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

export const JobPanel = ({ isExclusive, isFullTime, description, category, localization, contactNumber, hoursAgo, onUnlockClick, id }) => {
    const { width: windowWidth } = useWindowDimensions();
    const [redirectToDemand, setRedirectToDemand] = React.useState(false);

    const handleUnlockClick = () => {
        setRedirectToDemand(true);
    };

    if (redirectToDemand) {
        return <Redirect to={`/demand/${id}`} />;
    }

    return (
        <Card style={{ marginLeft: windowWidth > 550 ? 100 : 10, marginRight: windowWidth > 550 ? 100 : 10, color: '#545454' }}>
            <CardContent>
                {
                    isExclusive ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h4" color="inherit" style={{ marginLeft: 15 }} align="center">Demand for {category}</Typography>
                            <img src={exclusiveIcon} style={{ maxWidth: 150, marginLeft: 10, transform: 'rotateZ(15deg)' }} />
                        </div>
                        :
                        <Typography variant="h4" color="inherit" align="center">Demand for {category}</Typography>
                }
                <Grid container style={{ marginTop: 15 }}>
                    <Grid item sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Container>
                            {
                                description !== "" ?
                                    <JobSection title="Description:">
                                        {description}
                                    </JobSection>
                                    :
                                    <Typography variant="body1">There's no description</Typography>
                            }
                            <JobSection title="Contact:">
                                <List>
                                    <ListItem>
                                        {contactNumber}
                                    </ListItem>
                                </List>
                            </JobSection>
                        </Container>
                    </Grid>
                    <Grid item sm={12} md={6} style={{ borderLeft: windowWidth > 960 ? '1px solid #aeaeae' : '', color: '#888cc6', paddingLeft: 35, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <IconWithDescription icon={partTime} description={isFullTime ? "Full time" : "Part time"} />
                        <IconWithDescription icon={location} description={`${localization ? localization : "No specified"}`} />
                        <IconWithDescription icon={calendar} description={`Posted ${hoursAgo} ago`} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button variant="contained" color="secondary" onClick={handleUnlockClick}>
                    Unlock Demand <Icon style={{ marginLeft: 15 }}>visibility</Icon>
                </Button>
            </CardActions>
        </Card>
    )
}

const IconWithDescription = ({ icon, description }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
            <img src={icon} style={{ marginRight: 15 }} />
            <Typography variant="body1" color="inherit">{description}</Typography>
        </div>
    )
}

const JobSection = (props) => {
    return (
        <section>
            <Typography variant="h6" color="inherit">{props.title}</Typography>
            {props.children}
        </section>
    )
}
import React, { useState } from 'react';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';
import { Container, Card, CardContent, CardHeader, Typography, Grid, TextField, MenuItem } from '@material-ui/core';
import { DemandCard } from './DemandCard';
import { useFiltroCommonDemands, useCityStateFilter, useCategoriesFilter, useFiltroGoldenDemands } from './hooks';
import { DemandList } from './DemandList';

export const DemandsAvailableScreen = () => {
    const { width: windowWidth } = useWindowDimensions();

    const [stateCode, setStateCode] = useState('');
    const [city, setCity] = useState('');
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [search, setSearch] = useState('');
    const [commonPageIndex, setCommonPageIndex] = useState(0);
    const [goldenPageIndex, setGoldenPageIndex] = useState(0);

    const { commonDemandsPagedList, requestingCommonDemand } = useFiltroCommonDemands(stateCode, city, category, subcategory, search, commonPageIndex);
    const { goldenDemandsPagedList, requestingGoldenDemand } = useFiltroGoldenDemands(stateCode, city, category, subcategory, search, goldenPageIndex);

    const { selectedCity, selectedState, filteredCities, states, setSelectedCity, setSelectedState } = useCityStateFilter();
    const { categories, subcategories } = useCategoriesFilter(category);

    return (
        <section style={{
            background: 'linear-gradient(to bottom, #d2d2d2, transparent)', color: 'white',
            paddingTop: windowWidth > 960 ? 155 : 80
        }}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="primary" style={{ textAlign: 'center' }}>Demands Available</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ padding: 5 }}>
                        <TextField
                            select
                            fullWidth
                            label="State"
                            value={selectedState}
                            onChange={({ target }) => {
                                setSelectedState(target.value);
                                setCity('');
                                setStateCode(target.value);
                            }}
                            style={{ textAlign: 'left' }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value={""} style={{ color: '#424242' }}>Select a State...</MenuItem>
                            {
                                states.map(({ stateCode, state }, i) => (
                                    <MenuItem key={i} style={{ color: '#424242' }} value={stateCode}>{state}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ padding: 5 }}>
                        <TextField
                            select
                            fullWidth
                            label="City"
                            value={selectedCity}
                            onChange={({ target }) => {
                                setSelectedCity(target.value);
                                setCity(target.value);
                            }}
                            style={{ textAlign: 'left' }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value={""} style={{ color: '#424242' }}>Select a State...</MenuItem>
                            {
                                filteredCities.map(({ city }, i) => (
                                    <MenuItem key={i} style={{ color: '#424242' }} value={city}>{city}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ padding: 5 }}>
                        <TextField
                            select
                            fullWidth
                            label="Category"
                            value={category}
                            onChange={({ target }) => {
                                setCategory(target.value);
                                setSubcategory('');
                            }}
                            style={{ textAlign: 'left' }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value={""} style={{ color: '#424242' }}>Select a Category...</MenuItem>
                            {
                                categories.map(({ name }, i) => (
                                    <MenuItem key={i} style={{ color: '#424242' }} value={name}>{name}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ padding: 5 }}>
                        <TextField
                            select
                            fullWidth
                            label="Subcategory"
                            value={subcategory}
                            onChange={({ target }) => {
                                setSubcategory(target.value);
                            }}
                            style={{ textAlign: 'left' }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value={""} style={{ color: '#424242' }}>Select a Subcategory...</MenuItem>
                            {
                                subcategories.map(({ name }, i) => (
                                    <MenuItem key={i} style={{ color: '#424242' }} value={name}>{name}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary">Golden Demands</Typography>
                        <DemandList pagedList={goldenDemandsPagedList}
                            isLoading={requestingGoldenDemand}
                            onPageChange={(newIndex) => setGoldenPageIndex(newIndex)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary">Common Demands</Typography>
                        <DemandList pagedList={commonDemandsPagedList}
                            isLoading={requestingCommonDemand}
                            onPageChange={(newIndex) => setCommonPageIndex(newIndex)} />
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
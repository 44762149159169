import { STORE_JOBCOINS_PACKETS } from "./jobcoinsActionTypes";

const initialState = {
    allJobcoinsPackets: []
};

export const jobcoinsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_JOBCOINS_PACKETS:
            return {
                ...state,
                allJobcoinsPackets: action.packets
            };
        default:
            return state;
    }
};
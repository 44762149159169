import React, { Component } from 'react';
import { Typography, Container, Grid, Button, IconButton, Divider } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { connect } from 'react-redux';
import { Link as LinkRouter } from 'react-router-dom';

import googlePlay from '../../assets/google_play.png';
import appStore from '../../assets/app_store.png';
import logo from '../../assets/instajob_logo_bottom.png';

class Footer extends Component {
    _rediretTo = (url, target = undefined) => {
        if (target) {
            window.open(url, target);
        } else {
            window.location = url;
        }
    }

    render() {
        const { categories, onAboutUsClicked } = this.props;
        return (
            <Container style={{ color: 'white' }}>
                <Grid container>
                    <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                        <Typography variant="h5" color="inherit">Our services</Typography>
                        {
                            categories ?
                                categories.map((cat) => {
                                    return (<Button key={cat.id} variant="text" color="inherit" style={{ justifyContent: 'flex-start', textTransform: 'capitalize', textAlign: 'left' }}>{cat.name}</Button>);
                                })
                                :
                                null
                        }
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                        <Typography variant="h5" color="inherit">Institutional</Typography>
                        <Button variant="text" onClick={onAboutUsClicked} color="inherit" style={{ justifyContent: 'flex-start', textTransform: 'capitalize' }}>About us</Button>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                        <Typography variant="h5" color="inherit">Follow Us</Typography>
                        <div>
                            <IconButton color="inherit" style={{ marginRight: 5 }}>
                                <InstagramIcon />
                            </IconButton>
                            <IconButton color="inherit" style={{ marginRight: 5 }}>
                                <FacebookIcon />
                            </IconButton>
                            <IconButton color="inherit" style={{ marginRight: 5 }}>
                                <TwitterIcon />
                            </IconButton>
                            <IconButton color="inherit" style={{ marginRight: 5 }}>
                                <YouTubeIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    {/*<Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                        <Typography variant="h5" color="inherit">Download App</Typography>
                        <img src={googlePlay} alt="InstaJob Android App link for Play Store" style={{ cursor: 'pointer', maxWidth: '155px' }}
                            onClick={() => {
                                this._rediretTo("https://play.google.com/store/apps/details?id=com.instajob.app&hl=en", '_blank');
                            }} />
                        <img src={appStore} alt="InstaJob iOS App link for App Store" style={{ cursor: 'pointer', maxWidth: '155px' }}
                            onClick={() => {
                                this._rediretTo("https://apps.apple.com/br/app/instajobplan/id1476731092?l=en", '_blank');
                            }} />
                    </Grid>*/}
                </Grid>
                <Divider style={{ backgroundColor: '#aeaeae', marginTop: 25 }} />
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', paddingTop: 15, paddingBottom: 15 }}>
                    <img src={logo} alt="InstaJob" style={{ marginRight: 20 }} />
                    <Button component={LinkRouter} to="/privacy" variant="text" style={{ color: 'inherit' }}>Privacy Policy</Button>
                    <Button variant="text" color="inherit">Help</Button>
                    <Button component={LinkRouter} to="/terms" variant="text" style={{ color: 'inherit' }}>Terms</Button>
                    <Typography variant="body1" style={{ marginLeft: 20, textAlign: 'center' }}>Copyright © 2019 - InstaJob. All rights reserved.</Typography>
                </div>

            </Container>
        )
    }
}

const mapStateToProps = ({ categories }) => ({
    categories: categories.allCategories
});

export default connect(mapStateToProps)(Footer);
import React, { Component } from 'react';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default class PasswordField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        }

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    }

    togglePasswordVisibility() {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    render() {
        const { showPassword } = this.state;
        const { password, onChange, margin, id, variant, label, helperText, fullWidth } = this.props;
        return (
            <TextField
                margin={margin}
                variant={variant}
                id={id}
                label={label}
                fullWidth={Boolean(fullWidth)}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={onChange}
                helperText={helperText}
                auto
                InputProps={{                 
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="Toggle password visibility" onClick={this.togglePasswordVisibility}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        )
    }
}
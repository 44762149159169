import { CHANGE_STATE_FILTER, CHANGE_CITY_FILTER } from "./filterActionTypes";

export const changeStateFilter = (state) => {
    return dispatch => {
        dispatch({
            type: CHANGE_STATE_FILTER,
            state
        });
    };
};

export const changeCityFilter = (city) => {
    return dispatch => {
        dispatch({
            type: CHANGE_CITY_FILTER,
            city
        });
    };
};
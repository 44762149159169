import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSnackbar from './BaseSnackbar';

class SuccessSnackbar extends Component {
    render() {
        const { message, showSuccess } = this.props;
        return (
            <BaseSnackbar icon={'done'} show={showSuccess} message={message} color="#2e7d32" />
        );
    }
}

const mapStateToProps = ({ snackbars }) => ({
    message: snackbars.message,
    showSuccess: snackbars.showType.toLowerCase() === 'success'
});

export default connect(mapStateToProps)(SuccessSnackbar);
import React from 'react';
import WelcomePanel from './1 - Welcome Panel/WelcomePanel';
import ItsSimple from './2 - Its Very Simple/ItsSimple';
import About from './3 - About/About';
import YouAreProfessional from './4 - You Are Professional/YouAreProfessional';
import PanelCarousel from './5 - Demands Panel/PanelCarousel';
import MarketingCampaigns from './MarketingCampaigns';

const HomeScreen = props => {
    return (
        <>
            <WelcomePanel />
            <MarketingCampaigns />
            <ItsSimple />
            <About />
            <YouAreProfessional onRegisterServiceClick={() => props.toggleModalBeWorker(true)} />
            <PanelCarousel onUnlockClick={() => props.toggleModalUnlock(true)} />
        </>
    )
}

export default HomeScreen;
import React from 'react';
import useWindowDimensions from './hooks/useWindowDimensions';
import { useHistory } from 'react-router-dom';
import { Typography, Button, Container } from '@material-ui/core';

export const NotFound = props => {
    const { width: windowWidth } = useWindowDimensions();
    const { push } = useHistory();
    return (
        <div style={{ marginTop: windowWidth > 960 ? 160 : 60, backgroundColor: '#fff' }}>
            <Container>
                <Typography variant="h4" color="primary">Ops, something went wrong.</Typography>
                <Typography variant="body1">We try to locate a thing, but we cannot found it. Please, try again.</Typography>
                <Button variant="contained" color="secondary" onClick={() => push("/")}>Go to home page</Button>
            </Container>
        </div>
    )
}
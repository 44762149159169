import React, { useState } from "react";
import { Card, CardContent, CardHeader, Typography, Button, Tooltip, Modal } from '@material-ui/core';
import TextTruncate from 'react-text-truncate';
import { InlinePanel, TextIcon } from './styles';
import { QueryBuilder, Room, DateRange, Add, Star } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useDemandStatus } from './hooks'; 

export const DemandCard = ({ id, description, category, contactNumber, isFullTime, stateCode, city, hoursAgo, isGoldenDemand, isActive }) => {
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const { changeDemandStatus } = useDemandStatus(); // Use o novo hook useDemandStatus

    const modalStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        width: "30%",
        height: "30%",
        flexDirection: "column",
        position: "relative",
        padding: "20px",
      };
  
      const handleSeeMore = async () => {
        try {
            if (isActive) {
                // Enviar o valor "false" para desativar a demanda
                await changeDemandStatus(id, false);
                setModalMessage("successDeactivate");
            } else {
                // Enviar o valor "true" para ativar a demanda
                await changeDemandStatus(id, true);
                setModalMessage("successActivate");
            }
            setOpenModal(true);
        } catch (error) {
            console.error('Erro ao alterar o status da demanda:', error);
        }
    };

    const handleModalClose = () => {
        setOpenModal(false);
        window.location.reload();
    };

    return (
        <Card style={{ margin: 5 }}>
            {/*<CardHeader title={`Demand for ${category}`} >*/}
            <CardContent style={{ minHeight: 200 }}>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5' color="primary">Demand for {category}</Typography>
                    {
                        isGoldenDemand &&
                        <Tooltip title="Golden Demand" placement="top">
                            <Star style={{ color: '#ffa000' }} />
                        </Tooltip>
                    }
                </section>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>

                    <Typography variant='subtitle2' style={{ color: '#b0b0b0' }}>Description</Typography>
                    <Typography>
                        <TextTruncate
                            line={3}
                            element="span"
                            truncateText="..."
                            text={description} />
                    </Typography>
                </section>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    <Typography variant='subtitle2' style={{ color: '#b0b0b0' }}>Contact</Typography>
                    <Typography>{contactNumber}</Typography>
                </section>
                <section style={{ display: 'flex', flexGrow: 1 }}>
                    <InlinePanel>
                        <TextIcon>
                            <QueryBuilder />
                            {isFullTime ? 'Full Time' : 'Part Time'}
                        </TextIcon>
                        <TextIcon>
                            <Room />
                            {city} - {stateCode}
                        </TextIcon>
                        <TextIcon>
                            <DateRange />
                            Posted {hoursAgo} ago
                        </TextIcon>
                    </InlinePanel>
                </section>
                <section style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <InlinePanel style={{ justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginTop: 10 }}
                            onClick={handleSeeMore}
                        >
                            {isActive ? "Deactivate" : "Activate"}
                        </Button>
                    </InlinePanel>
                </section>
            </CardContent>
            <Modal
                open={openModal}
                onClose={handleModalClose}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div style={modalStyle}>
                    {modalMessage === "successActivate" && (
                        <p style={{ color: "black" }}>Demand activated successfully!</p>
                    )}
                    {modalMessage === "successDeactivate" && (
                        <p style={{ color: "black" }}>Demand deactivated successfully!</p>
                    )}
                    {modalMessage !== "successActivate" && modalMessage !== "successDeactivate" && (
                        <p style={{ color: "black" }}>
                            Failed to change demand status. Please try again.
                        </p>
                    )}
                    <Button onClick={handleModalClose}>OK</Button>
                </div>
            </Modal>
        </Card>
    );
};
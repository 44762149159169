import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./snackbarActionTypes";

const initialState = {
    showType: '',
    message: ''
}

export const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SNACKBAR:
            return {
                ...state,
                showType: action.showType,
                message: action.message
            };
        case HIDE_SNACKBAR:
            return initialState;
        default:
            return state;
    }
}
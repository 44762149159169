import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

export const NeedServices = ({ category, macroCategory }) => {
    const { width: windowWidth } = useWindowDimensions();
    let image;
    try {
        image = require(`../../assets/category-details/${macroCategory.trim().toLowerCase()}/${category.trim().toLowerCase()}.png`);
    }catch(e) {
        image = require(`../../assets/category-details/${macroCategory.trim().toLowerCase()}.png`);
    }

    return (
        <Grid container>
            <Grid item sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 35 }}>
                <Typography variant="h3" style={{ color: '#30458d' }}>Needing services for {category}</Typography>
                <Typography variant="subtitle1">Thousands of professionals evaluated by clients, allowing you to trade only with the best</Typography>
            </Grid>
            <Grid item sm={12} md={6} style={{ textAlign: windowWidth > 960 ? 'right' : 'center' }}>
                <img src={image} style={{ maxWidth: '100%' }} />
            </Grid>
        </Grid>
    )
};
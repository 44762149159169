import React, { Component, useState, useEffect } from 'react';
import { Typography, TextField, Button, Grid, Icon, Hidden, Container } from '@material-ui/core';
import { Fade } from 'react-slideshow-image';

import cleaner from '../../assets/banner_girl.png';
import homeWorker from '../../assets/banner_man.png';
import dancer from '../../assets/banner_dance.png';
import chef from '../../assets/banner_chef.png';
import babySitter from '../../assets/banner_baby_sitter.png';

import './BackgroundAnimation.css';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

const images = [{
    image: homeWorker,
    backgroundColor: '#f9c0c0',
    color: '#30458d'
}, {
    image: cleaner,
    backgroundColor: '#00d8c9',
    color: '#fff'
}, {
    image: dancer,
    backgroundColor: '#ffbe3c',
    color: '#fff'
}, {
    image: chef,
    backgroundColor: '#01adef',
    color: '#fff'
}, {
    image: babySitter,
    backgroundColor: '#cbebff',
    color: '#30458d'
}];

const WelcomePanel = (props) => {
    const [actualIndex, setActualIndex] = useState(0);
    const { width: windowWidth } = useWindowDimensions();

    function onChangeImage(oldIndex, newIndex) {
        setActualIndex(newIndex);
    }

    const panel = images.map((img, i) => (
        <img key={i} src={img.image} style={{ maxWidth: '100%', height: 602 }} />
    ));

    return (
        <section className="fundo" style={{
            width: '100%', height: 602, transition: 0.5, backgroundColor: images[actualIndex].backgroundColor,
            color: images[actualIndex].color, paddingTop: windowWidth > 500 ? 0 : 50, marginTop: windowWidth > 960 ? 145 : 0
        }}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} style={{
                        height: 602, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Typography variant="h3" color="inherit" style={{ width: '100%', transition: 0.5, textAlign: 'left' }}>Smartest way to hire a service</Typography>
                        <Typography variant="subtitle1" color="inherit" style={{ transition: 0.5, alignSelf: windowWidth > 960 ? 'flex-start' : 'inherit' }}>Tell us what you need, get budgets and choose what is best for you!</Typography>
                        {/*<TellUs label="Tell us what you want" continueText="Continue" />*/}
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: windowWidth > 600 ? 'flex-start' : 'center', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon>security</Icon>
                                <Typography variant="body2" style={{ marginRight: 25 }}>Free and secure budgets</Typography>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon>star</Icon>
                                <Icon>star</Icon>
                                <Icon>star</Icon>
                                <Icon>star</Icon>
                                <Icon>star</Icon>
                                <Typography variant="body2">Rated professionals</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={6}>
                            <Fade duration={3000} transitionDuration={500} infinite={true} indicators={false} arrows={false} onChange={onChangeImage}>
                                {panel}
                            </Fade>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </section>
    );
}

const TellUs = (props) => {
    const { width: windowWidth } = useWindowDimensions();

    return (
        <Grid container>
            <Grid item sm={12} md={9}>
                <TextField margin="normal" variant="filled" style={{ marginRight: 25 }} InputProps={{ style: { background: 'white' } }} fullWidth label={props.label} />
            </Grid>
            <Grid item sm={12} md={3} style={{ textAlign: 'right', marginTop: windowWidth > 960 ? 25 : 0, zIndex: 99 }}>
                <Button variant="contained" color="secondary" style={{ paddingLeft: 25 }}>
                    {props.continueText} <Icon style={{ marginLeft: 15 }}>arrow_forward</Icon>
                </Button>
            </Grid>
        </Grid>
    )
}

export default WelcomePanel;
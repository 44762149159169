import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import googlePlay from '../../assets/google_play.png';
import appStore from '../../assets/app_store.png';


export default class ModalUnlockDemand extends Component {
    _rediretTo = (url) => {
        window.location = url;
    }

    render() {
        const { show, handleClose } = this.props;
        return (
            <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Unlocking a demand</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">In order to unlock this demand, download your app and unlock in your account!</Typography>
                    <div style={{ display: 'flex', 'justifyContent': 'center', flexWrap: 'wrap', marginTop: 10 }}>
                        <img src={googlePlay} alt="InstaJob Android App link for Play Store" style={{ cursor: 'pointer', maxWidth: '155px' }}
                            onClick={() => {
                                this._rediretTo("https://play.google.com/store/apps/details?id=com.instajob.app&hl=en", '_blank');

                            }} />
                        <img src={appStore} alt="InstaJob iOS App link for App Store" style={{ cursor: 'pointer', maxWidth: '155px' }}
                            onClick={() => {
                                this._rediretTo("https://apps.apple.com/br/app/instajobplan/id1476731092?l=en", '_blank');

                            }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">Ok</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
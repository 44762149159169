import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import usageRules from '../../assets/usage-rules.pdf';

class ModalHowWorks extends Component {
    _rediretTo = (url) => {
        window.location = url;
    }

    render() {
        const { show, handleClose } = this.props;
        return (
            <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">How works InstaJob?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">There's our Usage Rules for InstaJob:</Typography>
                    <iframe src={usageRules} title="InstaJob's usage rules" style={{ width: 500, height: 300 }}></iframe>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ModalHowWorks;
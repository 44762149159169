import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

export default class PhoneNumberField extends Component {
    render() {
        const { inputRef, ...other } = this.props;

        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                showMask
            />
        )
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSnackbar from './BaseSnackbar';

class DefaultSnackbar extends Component {
    render() {
        const { message, showDefault } = this.props;
        return (
            <BaseSnackbar icon={'info'} show={showDefault} message={message} color="#212121" />
        );
    }
}

const mapStateToProps = ({ snackbars }) => ({
    message: snackbars.message,
    showDefault: snackbars.showType.toLowerCase() === 'default'
});

export default connect(mapStateToProps)(DefaultSnackbar);
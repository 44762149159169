import { storeJobcoinsPackets } from "../../redux/modules/jobcoins/jobcoinsActions";
import { getRequest, postRequest, handleErrors } from "../requests";
import { URL_BASE } from "../constants";
import store from "../../redux/globalStore";
import { SnackbarAPI } from "../../redux/modules/snackbars/snackbarApi";
import { refreshToken } from "./authServices";

export async function getAllJobcoinsPackagesAvailable() {
    const jobcoinsPayload = await getRequest(`${URL_BASE}/jobcoinspackets`);
    store.dispatch(storeJobcoinsPackets(jobcoinsPayload.data));
    return jobcoinsPayload;
}

async function getCreditCardToken(cardNumber, expMonth, expYear, cvc) {
    try {
        if (!cvc || cvc.length <= 0) {
            SnackbarAPI.error("Please provide your CVC.");
            return null;
        }
        const payload = await postRequest(`https://api.stripe.com/v1/tokens`,
            `card[number]=${cardNumber}&card[exp_month]=${expMonth}&card[exp_year]=${expYear}&card[cvc]=${cvc}`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.NODE_ENV === "production" ?
                        "pk_test_51MS5i3LL3my3HH6HUGS81VL3lwSBcXiZ8GjW6axJ7Pq8fs9ChroT30rhJGuvJHLXU6pQLaCwvvshaPr1F7Ff3VdH00wropzO2o"
                        :
                        "pk_test_51MS5i3LL3my3HH6HUGS81VL3lwSBcXiZ8GjW6axJ7Pq8fs9ChroT30rhJGuvJHLXU6pQLaCwvvshaPr1F7Ff3VdH00wropzO2o"}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        return payload.id;
    } catch (e) {
        if (e.status >= 400 && e.status < 500) {
            switch (e.data.error.code) {
                case "invalid_number":
                    SnackbarAPI.error("Ops, it's seems your credit card number or expiry date is invalid.");
                    break;
                case "invalid_expiry_year":
                    SnackbarAPI.error("Ops, it's seems your expiry date isn't correct.");
                    break;
                case "incorrect_number":
                    SnackbarAPI.error("Ops, this credit card information is wrong or incorrect, please review and try again.");
                    break;
                default:
                    SnackbarAPI.error("Oh, an error occurred while reading your credit card information.");
                    break;
            }
        }
        return null;
    }
}

export async function purchaseJobcoinsPacket(cardNumber, expMonth, expYear, cvc, idPacket) {
    const token = await getCreditCardToken(cardNumber, expMonth, expYear, cvc);
    if (!token) {
        return;
    }
    try {
        const userToken = store.getState().auth.loggedUser;
        const formData = new FormData();
        formData.append("idPacket", idPacket);
        formData.append("token", token);

        await postRequest(`${URL_BASE}/payments/request`, formData, {
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        await refreshToken();
        SnackbarAPI.success("Jobcoins Packet purchased successfully!");
        return true;
    } catch (error) {
        const { status, data } = error;
        handleErrors(status, data);
        return false;
    }
}
import {
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { getAllMarketingCampaigns } from "../../api/services/marketingCampaignServices";
import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";
import { getAllStates } from "../../api/services/statesServices";
import { getAllCities } from "../../api/services/cityServices";

const MarketingCampaigns = () => {
  const [loading, setLoading] = useState(false);
  const [firstGridCampaigns, setFirstGridCampaigns] = useState([]);
  const [secondGridCampaigns, setSecondGridCampaigns] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityDisabled, setCityDisabled] = useState(true);

  const { width: windowWidth } = useWindowDimensions();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));


  useEffect(() => {
    async function getCampaigns() {
      try {
        setLoading(true);

        const firstGridResponse = await getAllMarketingCampaigns(
          2,
          selectedState,
          selectedCity
        );
        const secondGridResponse = await getAllMarketingCampaigns(
          1,
          selectedState,
          selectedCity
        );

        setFirstGridCampaigns(firstGridResponse);
        setSecondGridCampaigns(secondGridResponse);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    getCampaigns();
  }, [selectedState, selectedCity]);

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await getAllStates();
        setStates(response);
      } catch (err) {
        console.error(err);
      }
    }

    fetchStates();
  }, []);

  useEffect(() => {
    async function fetchCities() {
      try {
        const response = await getAllCities(selectedState);
        setCities(response);
      } catch (err) {
        console.error(err);
      }
    }

    if (selectedState) {
      fetchCities();
      setCityDisabled(false);
    } else {
      setCities([]);
      setCityDisabled(true);
    }
  }, [selectedState]);

  const useStylesFirstGrid = makeStyles((theme) => ({
    card: {
      width: windowWidth > 900 ? 513 : "100%", // Use 513 for desktop, full width for mobile
      height: windowWidth > 900 ? 321 : 300,   // Use 321 for desktop, 300 for mobile
      margin: 1,
    },
    media: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  }));

  const useStylesSecondGrid = makeStyles((theme) => ({
    card: {
      width: windowWidth > 900 ? 323 : "100%", // Use 323 for desktop, full width for mobile
      height: windowWidth > 900 ? 323 : 300,   // Use 323 for desktop, 300 for mobile
      margin: 1,
    },
    media: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  }));

  const classesFirstGrid = useStylesFirstGrid();
  const classesSecondGrid = useStylesSecondGrid();

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity("");
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  return (
    <div
      style={{
        backgroundColor: "#ec2e48",
        color: "white",
        paddingTop: 50,
        paddingBottom: 100,
      }}
    >
      <Container>
        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 1 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="inherit"
                align="center"
                style={{ marginBottom: 20 }}
              >
                Partners
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="state-select-label">State</InputLabel>
                <Select
                  labelId="state-select-label"
                  id="state-select"
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <MenuItem value="">All</MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.code}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="city-select-label">City</InputLabel>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value={selectedCity}
                  onChange={handleCityChange}
                  disabled={cityDisabled}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.name} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {loading && (
                <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                  <CircularProgress />
                </div>
              )}
              {(firstGridCampaigns.length > 0 || secondGridCampaigns.length > 0) ? (
                <>
                  {firstGridCampaigns.length > 0 && !isMobile && (
                    <Slide
                      duration={5000}
                      indicators={false}
                      transitionDuration={200}
                      infinite
                      slidesToShow={windowWidth > 900 ? 2 : 1}
                      slidesToScroll={windowWidth > 900 ? 2 : 1}
                      style={{ marginBottom: 20 }}
                    >
                      {firstGridCampaigns.flatMap((campaign) =>
                        campaign.banners.map((banner, index) => (
                          <Card key={index} className={classesFirstGrid.card}>
                            <CardMedia
                              className={classesFirstGrid.media}
                              image={banner}
                              title={campaign.name}
                            />
                          </Card>
                        ))
                      )}
                    </Slide>
                  )}
                  {secondGridCampaigns.length > 0 && (
                    <Slide
                      duration={5000}
                      indicators
                      transitionDuration={200}
                      infinite
                      slidesToShow={windowWidth > 900 ? 3 : 1}
                      slidesToScroll={windowWidth > 900 ? 3 : 1}
                    >
                      {secondGridCampaigns.flatMap((campaign) =>
                        campaign.banners.map((banner, index) => (
                          <Card key={index} className={classesSecondGrid.card}>
                            <CardMedia
                              className={classesSecondGrid.media}
                              image={banner}
                              title={campaign.name}
                            />
                          </Card>
                        ))
                      )}
                    </Slide>
                  )}
                </>
              ) : (
                <Typography variant="body1" align="center">
                  No campaigns available
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default MarketingCampaigns;
import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

export const ZipCodeField = (props) => {
    const { inputRef, ...other } = props;

    const commonZipCode = [/\d/, /\d/, /\d/, /\d/, /\d/];
    const extendedZipCode = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide
            mask={function (rawValue) {
                if (rawValue.replace('-', '').trim().length > 5) {
                    return extendedZipCode;
                }
                return commonZipCode;
            }}
            placeholderChar={'\u2000'}
            showMask
        />
    )
};
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { hasLoggedUser } from "../../api/services/authServices";
import { getUserSubcategories, createUserSubcategory } from "../../api/services/userSubCategoryServices";

import { useCategoriesFilter } from "./hooks";
import {
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Modal,
} from "@material-ui/core";

export const InterestedCategoriesAdd = () => {
  const { width: windowWidth } = useWindowDimensions();

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [savedSubcategories, setSavedSubcategories] = useState([]); // Lista de subcategorias já salvas
  const history = useHistory();

  const useStyles = makeStyles((theme) =>
    createStyles({
      modal: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      selectedSubcategory: {
        color: "black",
        marginBottom: "10px",
      },
      cancelButton: {
        color: "white",
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "darkred",
        },
      },
    })
  );

  const classes = useStyles();

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    width: "30%",
    height: "30%",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  };

  const handleAddSubcategory = () => {
    if (subcategory && !selectedSubcategories.includes(subcategory)) {
      setSelectedSubcategories([...selectedSubcategories, subcategory]);
      setSubcategory("");
    }
  };

  const handleRemoveSubcategory = (selectedSubcategory) => {
    const updatedSubcategories = selectedSubcategories.filter(
      (subcategory) => subcategory !== selectedSubcategory
    );
    setSelectedSubcategories(updatedSubcategories);
  };

  const handleCreateDemand = async () => {
    setIsLoading(true);
  
    const newDemand = selectedSubcategories; // Apenas o array de subcategorias selecionadas
  
    try {
      await createUserSubcategory(newDemand);
      setModalMessage("success");
      setOpenModal(true);
    } catch (error) {
      console.error(error);
      setModalMessage("error");
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push("/"); // Redireciona o usuário para a home ("/")
  };

  const handleModalClose = () => {
    setOpenModal(false);
    if (modalMessage === "success") {
      history.push("/demands-available");
    }
  };

  const { categories, subcategories } = useCategoriesFilter(category);

  useEffect(() => {
    const checkUser = async () => {
      const isLogged = await hasLoggedUser();
      if (!isLogged) {
        history.push("/"); // Redireciona o usuário para a home ("/")
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    // Função para recuperar as subcategorias já salvas
    const fetchSavedSubcategories = async () => {
      try {
        const subcategoriesData = await getUserSubcategories();
        setSavedSubcategories(subcategoriesData);
      } catch (error) {
        console.error(error);
        console.log("Failed to fetch saved subcategories");
      }
    };
  
    fetchSavedSubcategories();
  }, []); // Executa apenas uma vez, ao montar o componente

  useEffect(() => {
    setSelectedSubcategories(savedSubcategories); // Define as subcategorias já salvas como as subcategorias selecionadas
  }, [savedSubcategories]); // Executa sempre que a lista de subcategorias salvas é atualizada

  return (
    <section
      style={{
        background: "linear-gradient(to bottom, #d2d2d2, transparent)",
        color: "white",
        paddingTop: windowWidth > 960 ? 155 : 80,
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="primary"
              style={{ textAlign: "center" }}
            >
              Opportunity Preferences
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Category"
              value={category}
              onChange={({ target }) => {
                setCategory(target.value);
                setSubcategory("");
              }}
              style={{ textAlign: "left" }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={""} style={{ color: "#424242" }}>
                Select a Category...
              </MenuItem>
              {categories.map(({ name }, i) => (
                <MenuItem key={i} style={{ color: "#424242" }} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Subcategory"
              value={subcategory}
              onChange={({ target }) => {
                setSubcategory(target.value);
              }}
              style={{ textAlign: "left" }}
              margin="normal"
              variant="outlined"
              disabled={!category}
            >
              <MenuItem value={""} style={{ color: "#424242" }}>
                Select a Subcategory...
              </MenuItem>
              {subcategories.map(({ name }, i) => (
                <MenuItem key={i} style={{ color: "#424242" }} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSubcategory}
              disabled={!subcategory || selectedSubcategories.includes(subcategory)}
            >
              Add Preference
            </Button>
          </Grid>

          <Grid item xs={12}>
            <div>
              {selectedSubcategories.map((selectedSubcategory, index) => (
                <div key={index} className={classes.selectedSubcategory}>
                  <Button
                    variant="contained"
                    color="default"
                    disabled
                    style={{ marginRight: "10px", color: "black" }}
                  >
                    {selectedSubcategory}
                  </Button>
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemoveSubcategory(selectedSubcategory)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <p style={{ color: "black", fontSize: "18px" }}>Loading...</p>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateDemand}
                  disabled={selectedSubcategories.length === 0}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleCancel}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={modalStyle}>
            {modalMessage === "success" ? (
              <p style={{ color: "black" }}>Preference added successfully!</p>
            ) : (
              <p style={{ color: "black" }}>
                Failed to add preference. Please try again.
              </p>
            )}
            <Button onClick={handleModalClose}>OK</Button>
          </div>
        </Modal>
      </Container>
    </section>
  );
};
import React from 'react';
import { Skeleton } from '@material-ui/lab'
import { Card, CardContent } from '@material-ui/core';
import { InlinePanel } from '../DemandCard/styles';

export const LoadingDemandCard = () => {
    return (
        <Card style={{ margin: 10 }}>
            <CardContent>
                <Skeleton variant="text" height={36} />
                <Skeleton variant="text" height={12} width={100} />
                <Skeleton variant="rect" height={125} />
                <Skeleton variant="text" height={12} width={75} />
                <Skeleton variant="text" height={12} width={100} />
                <InlinePanel>
                    <Skeleton variant="text" height={24} width={75} />
                    <Skeleton variant="text" height={24} width={75} />
                    <Skeleton variant="text" height={24} width={75} />
                </InlinePanel>
            </CardContent>
        </Card>
    )
}
import axios from 'axios';
import { showSnackbar } from '../redux/modules/snackbars/snackbarActions';
import store from '../redux/globalStore';

export async function handleErrors(status, errors) {
    const allErrors = [];
    if (status === 400 && !errors.success) {
        for (let error of errors.data) {
            allErrors.push(error.details);
        }
    }
    store.dispatch(showSnackbar('danger', allErrors.join(' | ')));
    return allErrors.join('\n');
}

export async function getRequest(url, params, configs, onError = null) {
    try {
        const payload = await axios.get(url, { ...configs, params });
        return payload.data;
    } catch (error) {
        if (onError && typeof onError === 'function') {
            onError(error.response);
            return;
        }
        throw error.response;
    }
}

export async function postRequest(url, body, configs, onError = null) {
    try {
        const payload = await axios.post(url, body, configs);
        return payload.data;
    } catch (e) {
        if (onError && typeof onError === "function") {
            onError(e.response);
        }
        throw e.response;
    }
}

export async function putRequest(url, body, configs, onError = null) {
    try {
        const payload = await axios.put(url, body, configs);
        return payload.data;
    } catch (error) {
        if (onError && typeof onError === 'function') {
            onError(error.response);
            return;
        }
        throw error;
    }
}

export async function deleteRequest(url, params, configs, onError = null) {
    try {
        const payload = await axios.delete(url, { ...configs, params });
        return payload.data;
    } catch (error) {
        if (onError && typeof onError === 'function') {
            onError(error.response);
            return;
        }
        throw error.response;
    }
}

export async function patchRequest(url, body, configs, onError = null) {
    try {
        const payload = await axios.patch(url, body, configs);
        return payload.data;
    } catch (error) {
        if (onError && typeof onError === 'function') {
            onError(error.response);
            return;
        }
        throw error;
    }
}
import { styled } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core';

export const InlinePanel = styled(Box)({
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row'
});

export const TextIcon = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    color: '#646464',
    fontSize: '0.8rem',
    fontWeight: 400,
    '& svg': {
        marginRight: 10
    }
})
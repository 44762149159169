import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import itsSimple from '../../assets/its_very_simple.png';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

const ItsSimple = () => {
    const { width: windowWidth } = useWindowDimensions();

    return (
        <section style={{ margin: 0, padding: 0, paddingBottom: windowWidth > 960 ? 0 : 25, paddingTop: windowWidth > 960 ? 0 : 25 }}>
            <Container>
                <Grid container style={{ margin: 0 }}>
                    <Grid item sm={12} xs={12} md={6} style={{ textAlign: 'center' }}>
                        <img src={itsSimple} alt="Women searching jobs" style={{ height: windowWidth > 1024 ? 680 : 'inherit', width: windowWidth > 960 ? 'inherit' : '70%' }} />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} style={{ margin: 0, padding: 0, textAlign: windowWidth > 960 ? 'right' : 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Container>
                            <Typography variant="h4" style={{ color: "#30458d", width: '100%' }}>It is very simple!</Typography>
                            <br />
                            <Typography variant="body1" style={{ color: "#30458d" }}>InstaJob it's quick and easy, you only need to follow 3 steps: Quickly search for the professional or service that you need, find what you're looking for, and remember it's safe and completely free. “Get prices directly from 3 qualified professionals that will contact you quickly to negotiate the service you need.” Choose the professional you like best, negotiate directly and schedule your service. Simple, fast and safe!</Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

// const DisplayData = (props) => {
//     return (
//         <div style={{ paddingTop: 10, paddingBottom: 10 }}>
//             <Typography align={props.align} variant="h2" style={{ fontSize: window.innerWidth > 750 ? 60 : 40 }} color="primary">{props.amount}</Typography>
//             <Typography align={props.align} variant="body2">{props.description}</Typography>
//         </div>
//     )
// };

export default ItsSimple;
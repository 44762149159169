import { AUTHENTICATE_USER, LOGOUT } from "./authActionTypes";

const initialState = {
    loggedUser: null,
    isAuthenticated: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            return {
                ...state,
                loggedUser: action.user
            }
        case LOGOUT:
            return {
                ...state,
                loggedUser: null
            }
        default:
            return state;
    }
};
import React from 'react';
import { Typography } from '@material-ui/core';

import './CategoryItem.css';

export function CategoryItem(props) {
    const { active, onClick, onMouseEnter, onMouseLeave } = props;
    return (
        <div className={`category-item ${active ? "selected" : ""}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
            {/* <Icon fontSize="large">{props.icon}</Icon> */}
            <img src={props.icon} alt="Category Item" style={{ width: '100%', height: 50 }} />
            {/* <Typography variant="body2">{props.children}</Typography> */}
        </div>
    );
}
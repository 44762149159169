import store from "../../globalStore";
import { showSnackbar } from "./snackbarActions";

export class SnackbarAPI {
    static success(message) {
        store.dispatch(showSnackbar('success', message));
    }

    static warning(message) {
        store.dispatch(showSnackbar('warning', message));
    }

    static error(message) {
        store.dispatch(showSnackbar('danger', message));
    }

    static default(message) {
        store.dispatch(showSnackbar('default', message));
    }
}
import { CHANGE_CITY_FILTER, CHANGE_STATE_FILTER } from "./filterActionTypes";

const initialState = {
    stateFilter: '',
    cityFilter: ''
};

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CITY_FILTER:
            return {
                ...state,
                cityFilter: action.city
            };
        case CHANGE_STATE_FILTER:
            return {
                ...state,
                stateFilter: action.state
            }
        default:
            return state;
    }
};
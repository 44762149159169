import React from 'react';
import { Card, CardContent, CardHeader, Typography, Button, Tooltip } from '@material-ui/core';
import TextTruncate from 'react-text-truncate';
import { InlinePanel, TextIcon } from './styles';
import { QueryBuilder, Room, DateRange, Add, Star } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const DemandCard = ({ id, description, category, contactNumber, isFullTime, stateCode, city, hoursAgo, isGoldenDemand }) => {
    const history = useHistory();
    return (
        <Card style={{ margin: 5 }}>
            {/*<CardHeader title={`Demand for ${category}`} >*/}
            <CardContent style={{ minHeight: 200 }}>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5' color="primary">Demand for {category}</Typography>
                    {
                        isGoldenDemand &&
                        <Tooltip title="Golden Demand" placement="top">
                            <Star style={{ color: '#ffa000' }} />
                        </Tooltip>
                    }
                </section>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>

                    <Typography variant='subtitle2' style={{ color: '#b0b0b0' }}>Description</Typography>
                    <Typography>
                        <TextTruncate
                            line={3}
                            element="span"
                            truncateText="..."
                            text={description} />
                    </Typography>
                </section>
                <section style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    <Typography variant='subtitle2' style={{ color: '#b0b0b0' }}>Contact</Typography>
                    <Typography>{contactNumber}</Typography>
                </section>
                <section style={{ display: 'flex', flexGrow: 1 }}>
                    <InlinePanel>
                        <TextIcon>
                            <QueryBuilder />
                            {isFullTime ? 'Full Time' : 'Part Time'}
                        </TextIcon>
                        <TextIcon>
                            <Room />
                            {city} - {stateCode}
                        </TextIcon>
                        <TextIcon>
                            <DateRange />
                            Posted {hoursAgo} ago
                        </TextIcon>
                    </InlinePanel>
                </section>
                <section style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <InlinePanel style={{ justifyContent: 'flex-end' }}>
                        <Button variant="outlined" color="secondary" style={{ marginTop: 10 }} startIcon={<Add />} onClick={() => history.push(`/demand/${id}`)}>See more</Button>
                    </InlinePanel>
                </section>
            </CardContent>
        </Card>
    )
}
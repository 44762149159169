import store from "../../redux/globalStore";
import { showSnackbar } from "../../redux/modules/snackbars/snackbarActions";

export const isUserValid = (zipCode, phoneNumber, fullname, email) => {
    if (!zipCode || zipCode.length <= 0) {
        store.dispatch(showSnackbar('warning', 'Zip Code is required.'));
        return false;
    }

    if (!zipCode.match(/^[0-9]{5}(?:-[0-9]{4})?$/)) {
        store.dispatch(showSnackbar('warning', 'Zip Code is invalid.'));
        return false;
    }

    if (!phoneNumber || phoneNumber.length <= 0) {
        store.dispatch(showSnackbar('warning', 'Phone Number is required.'));
        return false;
    }

    // if (!phoneNumber.match(/^[+]*[(]{0,1}[0-9]{3}[)]{0,1}[\\s]{0,1}[-\\./0-9]{3}[-]{1}[-\\./0-9]{4}$/)) {
    //     store.dispatch(showSnackbar('warning', 'Phone Number is invalid.'));
    //     return false;
    // }

    if (!fullname || fullname.length <= 3) {
        store.dispatch(showSnackbar('warning', 'Full Name is required.'));
        return false;
    }

    if (!email || email.length <= 3) {
        store.dispatch(showSnackbar('warning', 'E-mail Address is required.'));
        return false;
    }

    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        store.dispatch(showSnackbar('warning', 'E-mail Address is invalid.'));
        return false;
    }

    return true;
}
import React, { Component } from "react";
import { Typography, Container, Grid } from "@material-ui/core";

import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";

export const Terms = (props) => {
  const { width: windowWidth } = useWindowDimensions();
  return (
    <section
      style={{
        background: "linear-gradient(to bottom, #d2d2d2, transparent)",
        color: "black",
        paddingTop: windowWidth > 960 ? 155 : 80,
      }}
    >
      <Container
        style={{
          textAlign: "justify",
          width: "70%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="inherit">
          InstaJob General Terms and Conditions of Use{" "}
        </Typography>
        <article>
          <Typography variant="body2" color="inherit" style={{ marginTop: 15 }}>
            <h2>The general terms and conditions of use of InstaJob </h2>

            <p>
              INSTAJOB WORK LLC (“InstaJob”), Document Number L17000237580 / FEI
              / EIN Number 82-3456684, is a legal entity governed by private law
              providing online advertising services between promising
              contractors (“Contractor”) and promising Providers
              (“Professional”) through the InstaJob application and website
              (“Platform”).
            </p>

            <p>
              Through this Privacy Policy (“Privacy Policy”), Instajob clarifies
              to users in general, Contractors and Providers (all collectively
              referred to as “Users”) its practices with regard to the privacy
              of information provided by Users, in addition to demonstrating our
              commitment to safeguard your privacy and protect your personal
              data.
            </p>

            <p>
              Through these General Terms and Conditions of Use (“Terms”),
              InstaJob presents to users in general, Contractors and Providers
              (all together referred to as “Users”) the essential conditions for
              the use of the services offered on the Platform.
            </p>
            <p>
              By using the Platform or using the services offered by InstaJob,
              Users accept and abide by the conditions of these Terms and the
              Privacy Policies, as well as all documents attached thereto.
            </p>
            <p>
              We are InstaJob and have created an online and mobile service that
              directly connects jobseekers and temporary services to companies
              that hire temporary jobs and permanent jobs. These Terms of Use
              (the "Terms" or "Agreement", including our Privacy Policy) apply
              to anyone who uses or accesses our site (including
              www.instaJob.com and www.instajob.work), software platform,
              applications. mobile, content and services.
            </p>
            <p>
              If you use any of our Services, you agree to be bound by these
              Terms and you agree that: you have read and understood these
              Terms; is at least eighteen (18) years old and has legal authority
              to accept these Terms; and agree to be legally bound by these
              Terms. If you do not wish to be bound by these Terms or if you do
              not agree to these Terms, you may not use or access our Services.
              PLEASE READ THESE TERMS CAREFULLY AS A LEGAL AGREEMENT BETWEEN YOU
              AND INSTAJOB.
            </p>

            <p>
              PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO
              ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL
              RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
              REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
              AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU
              WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST INSTAWORK ON AN
              INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS
              OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE
              PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
              DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE
              ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR
              IN A COURT OF LAW. GENERAL TERMS APPLICABLE TO ALL USERS OF OUR
              SERVICES You acknowledge that INSTAJOB does not control and cannot
              control the behavior of users of our Services, including Workers
              and Companies. We do our best to ensure that all users of our
              services comply with these Terms, but we are not responsible if
              they do not. 1. OBJECT The services object of these Terms consists
              of: 1. Allow Contractors to use the Platform freely and without
              direction or interference to search Provider budgets and View job
              and job posting opportunities on the Opportunity Dashboard 2. The
              Job Coin Usage Model (currency) presents the budgets required by
              the Employers to the Providers (“Budgets”), which may or may not
              offer their Services (“Services”) to the Employer. 3. Enable
              direct contact between Providers and Contractors interested in
              purchasing the Services by disclosing contact information from one
              party to the other. 4. Enable the connection between companies
              seeking professionals and professionals looking for some job and
              job opportunity. 5. Allow partner companies to publish (or not)
              free of charge in the Opportunity Panel the vacancies and job and
              job opportunities for all professionals registered in the
              platform, thus having the company the control of suspending,
              placing publication time in all the vacancies published. 6.
              InstaJob therefore enables Users to contact and negotiate directly
              with each other without intervening in contact, negotiation or
              execution of business, and is not, as such, a provider of any
              Services advertised by its Users on the Platform. 7. As a
              Classified Services, InstaJob does not impose or interfere with
              any negotiation regarding condition, value, quality, form or term
              in the contracting between the Contractors and Providers, nor does
              it guarantee the quality or delivery of the Services contracted
              between the Users. 8. By registering, the User may use all
              services available on the Platform available for his / her region,
              declaring to have read, understood and accepted the Terms. 2.
              CAPACITY TO REGISTER: 1. InstaJob services are available to
              individuals and legal entities that are regularly enrolled in
              federal and state taxpayer registries that have the legal capacity
              to hire them. Therefore, people who do not have this ability,
              including minors or people who have been temporarily or
              permanently disabled from InstaJob, cannot use them. Users are now
              warned of the legal sanctions set forth in the Civil Code. 2. It
              is forbidden to create more than one registration per User. In
              case of multiple entries made by a single User, InstaJob reserves
              the right in its sole discretion and without prior consent or
              communication to Users, disqualify all existing entries and
              prevent any future entries linked to them. 1. It will only be
              allowed to link a registration to an identification document, a
              zipcode, a telephone and an email, and there can be no duplicate
              data in any case. 3. InstaJob may unilaterally delete Users'
              records when it is found that User's conduct is or will be harmful
              or offensive to other Users, InstaJob and its employees or third
              parties. 3. REGISTRATION 1. It is necessary to completely complete
              all personal data required by InstaJob at the time of
              registration, in order for the User to be able to use the
              Platform. 2. It is the sole responsibility of Users to provide,
              update and ensure the accuracy of registration data, and InstaJob
              shall not be liable for any civil or criminal liability resulting
              from untrue, incorrect or incomplete data provided by Users. 3.
              InstaJob reserves the right to use all valid and possible means to
              identify its Users, as well as to request additional data and
              documents that it deems pertinent in order to verify the informed
              personal data. 4. If InstaJob considers a registration, or the
              information contained therein, suspected to contain erroneous or
              misleading data, InstaJob reserves the right to suspend,
              temporarily or permanently, the User responsible for the
              registration, as well as to prevent and block any advertising or
              registration of Services and cancel advertisements published by
              this, without prejudice to other measures deemed necessary and
              timely. Should any of these sanctions apply, Users will not be
              entitled to any indemnity or compensation for loss and damage,
              lost profits or moral damages. 5. The User will access their
              account by nickname (login) and password, committing not to inform
              third parties of this data, being fully responsible for the use
              made of them. 6. The User undertakes to notify InstaJob
              immediately through the contact channels maintained by InstaJob on
              the Platform regarding any unauthorized use of their account. The
              User will be solely responsible for the operations performed on
              his account, since access will only be possible through the use of
              password of his own knowledge. 7. Under no circumstances will the
              assignment, sale, rent or other form of account transfer be
              permitted. The creation of new registrations by persons whose
              original registrations have been canceled for violations of
              InstaJob policies will not be allowed. 8. The nickname You use on
              InstaJob may not bear any resemblance to the InstaJob name nor may
              any nickname be used that implies or suggests that the Services
              will be provided by InstaJob or are part of their promotions.
              Nicknames deemed offensive or in violation of applicable law will
              also be eliminated. 1. Providers are not permitted to present
              themselves to Employers as InstaJob employees, contractors or
              contractors. The Contractors understand that the Providers are not
              subordinate to InstaJob in any way. 9. InstaJob reserves the
              right, unilaterally and without notice, to refuse any registration
              request and to cancel a previously accepted registration. 4.
              MODIFICATIONS OF GENERAL TERMS AND CONDITIONS 1. InstaJob may at
              any time and in its sole discretion amend these Terms. The new
              Terms will be effective 10 (ten) days after they are posted on the
              Platform. Within 5 (five) days from the publication of the
              modifications, the User must inform, by email, if they do not
              agree with the amended terms. In this case, the contractual bond
              will cease to exist as long as there are no outstanding accounts
              or debts. If there is no statement within the stipulated period,
              it will be understood that the User has tacitly accepted the new
              Terms, and the agreement will continue to bind the parties. 1.
              Amendments shall not apply in respect of negotiations between
              Provider and Contractor already commenced at the time such changes
              are published. For these only, the Terms will apply to the
              previous wording. 2. The services offered by InstaJob may differ
              for each region of the country. These Terms shall be construed
              according to the region in which the Provider has registered. 5.
              ACCEPT BUDGET 1. In the Job Coin Model, Contractors will provide
              basic information about the Services they wish to contract and
              Budgets will be made available to Providers who can freely choose
              whether to have access to the Contractor's contact information. 2.
              The Contractor's contact details will only be made available to
              the Provider after the discount of a predetermined number of Coins
              ("Job Coin"), which may be purchased by the Provider. 3. The
              Provider acknowledges that by spending Job Coin to view the
              Contractor's contact details, the Provider will only be paying to
              access this data and may not hold InstaJob liable for any failure
              to negotiate with the Contractor, whichever for any reason,
              including outdated or incorrect registration data entered by the
              Employer. 4. Registered Professionals will have access to the
              Opportunities Panel, being able to view basic information of job
              and job postings, thus choosing, through payment by Currency
              (JobCoin) to have access to the full information of active
              publications in the Opportunity Board to Personal contact of
              professionals with the companies that are publishing them to
              negotiate working time, salary among others, so I do not have
              Instajob any responsibility or interference with this negotiation.
              6. VIRTUAL CREDIT COUPONS 1. All Service Providers undertake and
              must receive at least one virtual credit coupon in services
              negotiated with the Contractors, and the Service Provider may
              receive as many as he wishes, according to his negotiation with
              the customer, but is required to receive at minus one coupon per
              service provided, where this coupon will be used on the platform
              to purchase Budget wells. 2. All Contractors (customers) may
              receive from Instajob through campaigns and others, virtual credit
              coupons, which must be used with the payment of the requested
              services, knowing that each Service Provider is only required to
              receive 1 coupon. per service provided, whether or not they can
              accept more than one coupon for each service negotiated. 3.
              Virtual Credit Coupons are only valid for use within the InstaJob
              platform, whether for payment with the Service Provider (as a
              discount), or for the purchase of budget viewing packages, VIRTUAL
              CREDIT COUPONS NOT HAS MONETARY / ECONOMIC VALUE, CANNOT BE
              REFUNDED OR USED FOR ANY ACTIVITY OTHER THAN INSTAJOB PLATFORM AS
              ITEM 3 7. PRIVACIDADE DA INFORMAÇÃO 1. All information or personal
              data provided by the InstaJob User is stored on servers or high
              security magnetic media. 2. InstaJob will take all reasonable
              steps to maintain the confidentiality and security described in
              this clause, but will not be liable for any damage that may be
              caused by a third party using such improper, fraudulent or illegal
              means to access the information. stored on the servers or
              databases used by InstaJob. 3. InstaJob may use and assign data
              provided by Users and information relating to the use of the
              platform for any purpose, including, but not limited to,
              commercial, advertising, educational and journalistic purposes. 4.
              InstaJob may also use User and Partner data for analysis, study,
              reporting, as necessary for the proper functioning and development
              of the platform and services offered. 5. Users agree that their
              personal information will be sent to the other Party, Provider or
              Contractor, so that communication between the Parties is possible
              in the event of attempted contracting of the service. 6. InstaJob
              will collect information pertaining to transactions or other
              business conducted through InstaJob and may use or disclose it for
              business purposes or to improve its functionality. 7. InstaJob
              does not have access to the content of links or communications
              between Users. 8. InstaJob, for legal reasons, will keep in your
              database all information coletadas dos Usuários que excluírem seus
              cadastros. 2. 9. The username and password of each User is to
              ensure privacy and security. InstaJob recommends its Users not to
              share this information with anyone. InstaJob is not responsible
              for damages or damages caused to the User by sharing this
              information. 3. 10. All information provided by Users to InstaJob
              is protected by specialized companies. 4. 11. InstaJob follows the
              security standards commonly used by data transmission and
              retention companies to ensure the security of their Users.
              However, no method of transmitting or retaining electronic data is
              fully secure and may be subject to external attacks. Thus, despite
              using all possible and appropriate means, InstaJob cannot
              guarantee the absolute security of the information provided. 5.
              12. InstaJob collects and stores all information provided by its
              users while using its platform, including at the time of
              registration, for its own use and that of its trading partners. 6.
              13. Your name, email and other data may be used to send you
              notifications, account information or Services provided, notices
              of violations of the Term and other communications that InstaJob
              deems necessary. Users may request InstaJob to be deleted from
              their mailing list. 7. 14. InstaJob will provide all information
              requested by public agencies, provided it is duly justified and
              compatible with the law in force. 8. 15. InstaJob uses cookies and
              tracking software from its Users to provide its Users with the
              best possible browsing, based on their needs, and for internal
              searches. These cookies do not collect personal information, only
              inform each user's usage and browsing preferences, and provide
              InstaJob with statistics and data to improve their services.
              Prohibited Uses Use of our Services in the following ways is
              prohibited (this is not intended to be a complete or exhaustive
              list, but it will give you an idea of the sorts of things that are
              not allowed): Providing any User Content that violates any laws,
              infringes the rights (including the intellectual property rights)
              of others, is defamatory or otherwise creates liability for us;
              Copying our Services or using our Services to enable any service
              that is competitive with us; Posting links to any third-party
              websites through our Services; Scraping, scanning or otherwise
              using our Service or any information or the meta-data associated
              therewith to collect or compile data about other users (including
              Workers or Businesses); Reselling any information obtained from us
              or while using our Service; Attempting to compromise the integrity
              or security of the Services in any way; Decompiling, reverse
              engineering or disassembling the Services in any way; Reproducing,
              modifying, preparing derivative works based upon, distributing,
              licensing, leasing, selling, reselling, transferring, publicly
              displaying, publicly performing, transmitting, streaming,
              broadcasting or otherwise exploiting the Services in any way
              except as expressly permitted by INSTAJOB; Accessing the Services
              using any automated processes (such as robots, spiders, etc.) for
              any reason; or Harassing, defaming or discriminating against
              anyone or any entity for any reason. Indemnification You agree to
              defend, indemnify and hold INSTAJOB and its partners, agents,
              affiliates, officers, directors, representatives, employees, third
              party providers and content providers harmless from and against
              any and all liabilities, claims, demands, losses, liabilities,
              costs and expenses, including attorneys’ fees, that arise out of
              or in connection with: (i) your use of the Services; (ii) your
              breach or violation of these Terms; (iii) your User Content and
              INSTAJOB'S use of your User Content; or (iv) your violation of the
              rights of any third party, including third party providers. RATES
              1. Providers may purchase redeemable Job Coin which will serve as
              the virtual currency used to trigger the functionality of the
              InstaJob AB (access to budget) platform, especially access to
              Contractors contacts, and may use the Virtual Credit Coupons
              received. in services provided to the Contractors to purchase
              Budget access packages. 2. Job Coins may be purchased online from
              the InstaJob website or through the InstaJob application. 3. The
              price and availability of Job Coin and AB Budgets are subject to
              change without notice, and there may be dynamic variation based on
              factors such as location, type and offer of budgets. 4. The
              Provider is solely responsible for verifying that the correct
              amount of Job Coin has been added or deducted from its account
              during any transaction. It is the Provider's obligation to inform
              InstaJob of any failure or error. 9. CANCELLATION POLICY 1. Job
              Coins purchased by the Provider will not be returned to the
              Provider or converted to current Job Coin if the Provider decides
              to stop using the InstaJob platform. 2. The Job Coin acquired will
              be valid for one (1) year from the moment of purchase. After this
              period, expired Job Coins will be deducted from the Provider's
              account, which cannot claim any rights to them. 3. The Provider
              who does not wish to carry out an automatic renewal of the Job
              Coin Model shall, within 7 (seven) days after the automatic
              renewal and only if he has not used Job Coin acquired for the
              renewal. , inform InstaJob that you do not wish to renew. After
              this period, a fine of 20% of the contracted amount will be
              charged due to termination. If the Provider has used Job Coin for
              renewal, termination will not be permitted. 10. PROHIBITED
              SERVICES AND ADS AND BUDGETS 1. InstaJob is an online classifieds
              platform for Services, and is prohibited from serving any
              advertisement or request for a quote for sale, rent, exchange or
              any form of transfer of ownership or ownership of any movable or
              immovable property. 2. The advertising of illegal services in
              accordance with current legislation or that may be considered
              offensive to third parties is also prohibited. 3. InstaJob will
              unilaterally and without prior notice exclude any User who
              violates the rules contained in this section. 1. InstaJob does not
              pre-curate advertisements served on the Platform. 4. Any User or
              natural or legal person who is offended by any advertisement
              served on the Platform may request InstaJob to delete that
              advertisement from its Platform through its service channels. 11.
              QUALIFICATION SYSTEM OF PROVIDERS 1. Providers accept and submit
              to the qualification system adopted by InstaJob. 2. The Providers
              agree that InstaJob may cancel, delete or indefinitely suspend
              entries that repeatedly have negative qualifications. 3. The
              Providers shall not assist any type of indemnity or compensation
              for loss and damage, lost profits and moral damages, due to the
              qualification attributed to the announced Services. 1. If the
              excluded Provider has contracted a plan, InstaJob shall return the
              amounts of the contracted plan within 60 days, in proportion to
              the number of Job Coins remaining from the contract until the
              effective exclusion and imposing a 20% fine (twenty per cent).
              percent) on the balance. 2. The excluded Provider must send a copy
              of the Drive License and proof of residence to be entitled to the
              return of values. The 60-day deadline for the return of amounts
              will begin to run from the date of receipt of all documents by
              InstaJob. 4. Since the comments posted are the personal opinions
              of the Users, they will be responsible for the opinions posted on
              the Platform, either before InstaJob, the Providers, third
              parties, government agencies or other Users of the site, exempting
              InstaJob from any liability related to the posting. of the
              comments. 1. InstaJob had not excluded comments or qualifications
              about Providers. Only the User responsible for the comment or
              rating may remove or change the comments or ratings. 5. InstaJob
              reserves the right to exclude unilaterally and in its sole
              discretion comments containing and registering Users who practice:
              1. Offense to the honor, image, reputation and dignity of others;
              2. Pornography, pedophilia, and other modalities of sexual
              satisfaction; 3. Racism or discrimination of any kind; 4.
              Bullying, Stalking or any other kind of illegal embarrassment or
              harassment; 5. Expresses infringement of copyright or image
              rights; 6. Use of third-party trademarks, symbols, logos or
              emblems; 7. Instigation or apology for the commission of crimes,
              such as drug trafficking or use, rape, murder, estelionate, among
              others; 8. Errors or suspected misconceptions. 12.OBLIGATIONS OF
              USERS 1. The Provider must have legal capacity to provide the
              Service. 2. Because InstaJob does not appear as a party to
              Services contracting transactions between Users, the liability for
              all obligations arising therefrom, whether tax, labor, consumer or
              otherwise, shall be solely the Contractor, the Provider or both,
              as appropriate. In the event of a judicial complaint filed by
              Defendant InstaJob, whose facts are based on the actions of the
              Provider, the Provider shall be called to the process and shall
              bear all the resulting costs, including expenses with fees, fees,
              agreements, attorney's fees, among others. . Since it is not a
              party to transactions between users, InstaJob cannot oblige users
              to honor their obligations or to negotiate. 3. The Provider should
              keep in mind that, to the extent that it acts as a service
              provider, its offer is bound by it, pursuant to Article 30 of the
              Consumer Protection Code and Article 429 of the Civil Code, which
              compliance may be required. by the Contractor 4. InstaJob is not
              responsible for the tax obligations that fall on the activities of
              the Users. As required by applicable law, the Employer shall
              require the Provider's invoice in its transactions. The Provider,
              in accordance with current law, will be responsible for the
              fulfillment of the full obligations arising from its activities,
              especially those related to taxes levied. 13.SEALED PRACTICES 1.
              It is strictly forbidden to Users, among other actions provided
              for in these Terms, to directly or indirectly manipulate the
              prices of the Services advertised. 2. Providers are prohibited
              from advertising the same advertisement, content, item or service
              in more than one category and / or repeatedly. InstaJob reserves
              the right to exclude duplicate advertisements or services, as well
              as suspend or delete the registration of the Provider responsible
              for duplication. 3. Users may not: (i) Obtain, store, disclose,
              market and / or use personal data about other Users for commercial
              or unlawful purposes; (ii) Use automated means, including spiders,
              robots, crawlers, data capture tools or the like to download data
              from the site (except Internet search tools and non-commercial
              public files); (iii) circumvent, or attempt to circumvent, in any
              way, the site's system, mechanism and / or platform; and (iv)
              include means of contact such as telephone, email, address and
              other forms of communication in the offers. 14.INFRINGEMENT IN THE
              SYSTEM OR DATABASE 1. The use of any device, software, or other
              feature that may interfere with Platform activities and
              operations, as well as advertisements, descriptions, accounts, or
              their databases is prohibited. Any intrusion, attempted, or
              activity that violates or contravenes intellectual property law
              and the prohibitions set forth in these Terms will render you
              liable to suffer the effects of the relevant legal actions and
              sanctions set forth herein, and you are liable for damages.
              InstaJob or its Users for any damages caused. 15. SANCTIONS 1.
              Without prejudice to other measures, InstaJob may, in its sole
              discretion and without prior consent or communication to the
              Users, temporarily or permanently warn, suspend or cancel the
              User's registration or advertisements and may impose a sanction
              that impacts negatively on your reputation at any time by
              initiating reasonable legal action and suspending the provision of
              your services if: (i) You fail to comply with any provision of
              these Terms and other InstaJob policies; (ii) breach of its User
              duties; (iii) commit criminal or criminal acts; (iv) the identity
              of the User cannot be verified, any information provided by the
              User is incorrect or if the information provided leads us to
              believe that the registration is false or of a different person;
              (v) InstaJob understands that the advertisements or any other
              action by the User has caused damage to third parties or InstaJob
              or has the potential to do so. 2. In the event of temporary or
              permanent suspension of the Provider's registration, all active
              announcements and offers made will be automatically canceled. 1.
              In case of temporary suspension, the plan hired by the Provider
              shall be suspended until the rehabilitation of the Provider's
              register, when it will be effective again. 2. In the event of
              permanent suspension, InstaJob will return the amounts of the
              contracted package within 60 days, in proportion to the number of
              Coins (Job Coin) remaining from hiring to effective exclusion. 3.
              In case of permanent suspension or exclusion of the Provider due
              to evidence or suspicion of fraud against InstaJob or Contractors,
              InstaJob will not return the values of the contracted plan. 3.
              InstaJob reserves the right, at any time and in its sole
              discretion, to request the submission of personal documentation or
              any document proving the accuracy of the registration information.
              1. In the event of a request for documents, any time limits set
              forth in these Terms shall only apply from the date of receipt of
              the documents requested from the Provider by InstaJob. 16.
              RESPONSIBILITIES 1. InstaJob is not responsible for technical or /
              or operational defects or defects arising from the user's system
              or third parties. 2. InstaJob is not responsible for the delivery
              of the Services announced by the Providers on the Platform. 3.
              InstaJob is also not responsible for the existence, quantity,
              quality, status, integrity or legitimacy of the Services offered
              or contracted by Users, as well as for the ability to contract
              from Users or for the accuracy of personal data provided by Users.
              InstaJob, as it is not the owner, depositor or holder of the
              products offered, does not grant warranty for hidden or apparent
              defects in negotiations between Users. Each User knows and agrees
              to be solely responsible for the Services they advertise or the
              offers they make. 4. InstaJob shall not be liable to reimburse its
              Users for any expenses with telephone calls, data packets, SMS,
              messages, emails, correspondence or any other amount spent by User
              as a result of contacting InstaJob or any other User for any
              reason whatsoever. so be it. 5. InstaJob cannot be held
              responsible for the effective fulfillment of the obligations
              assumed by the Users. Users acknowledge and agree that in doing
              business with other Users, they do so at their own risk,
              recognizing InstaJob as a mere provider of virtual space
              availability services for the announcement of Services offered by
              third parties. 6. In no event will InstaJob be liable for any loss
              of profit or any other damage and / or loss that the User may
              suffer due to negotiations or not made through the Platform,
              arising from the conduct of other Users. 7. Because these
              transactions are conducted electronically between two Users who
              did not know each other prior to the negotiation, InstaJob
              recommends that every transaction be carried out with caution and
              prudence. 8. If one or more Users or any third party initiates any
              type of claim or legal action against another or other Users, each
              and every User involved in the claims or actions disclaims all
              responsibility InstaJob and its directors, managers, employees,
              agents, workers, representatives and prosecutors. 9. InstaJob
              reserves the right to assist and cooperate with any judicial
              authority or government agency, and may submit registration or
              business information from its Users, when it deems that its
              assistance or cooperation is necessary to protect its Users,
              employees, employees, administrators, partners. or anyone who may
              be harmed by the action or omission fought. 17. REACH OF SERVICES
              1. These Terms do not generate any partnership, mandate,
              franchise, or employment relationship between InstaJob and the
              User. You acknowledge that InstaJob is not part of any transaction
              between Users, nor has any control over the quality, safety or
              legality of the Services advertised by Users, the truthfulness or
              accuracy of advertisements made by Users, and Users' ability to
              negotiate. 2. InstaJob cannot guarantee the success of any
              transaction between Users, nor verify the identity or personal
              data of Users. InstaJob does not guarantee the accuracy of the
              third party posting that appears on its Platform and will not be
              responsible for the correspondence or agreements that the User
              makes with third parties. 1. InstaJob may offer Providers a
              conference service to ensure their identity and registration data.
              InstaJob may inform Contractors when this service has been used by
              the Provider. 2. The Provider who circumvents the conferencing
              system in order to receive unlawful advantages shall be excluded
              from the Platform and appropriate legal action may be taken,
              especially of a criminal nature. 18. PROBLEMS ARISING FROM THE USE
              OF THE SYSTEM 1. InstaJob is not liable for any damage, injury or
              loss suffered by the User due to failures in his internet
              connection, his provider, the system, the SMS system, the
              telephone line or the server used by the User. User, arising from
              the conduct of third parties, act of God or force majeure. 2.
              InstaJob is not responsible for the compatibility between your
              Platform and User-owned hardware. The User shall keep their
              equipment up to date and shall not be liable to InstaJob if the
              Platform is not accessible on outdated equipment. 3. InstaJob will
              also not be responsible for any viruses, trojans, malware, spyware
              or any software that may damage, change settings or infiltrate
              User equipment as a result of accessing, using or browsing the
              Internet, or as a consequence of downloading. data, information,
              files, images, text or audio. 19. INTELLECTUAL PROPERTY AND LINKS
              1. The commercial use of the term "InstaJob" as a trademark, trade
              name or domain name, as well as logos, trademarks, insignia,
              content of the screens relating to the Platform services and the
              suite of programs, databases, networks and files. which allow you
              to access and use your account, are the property of InstaJob and
              are protected by international copyright, trademark, patent, model
              and industrial design laws and treaties. The misuse and total or
              partial reproduction of such content is prohibited, except with
              the express permission of InstaJob. Disputes, Governing Law and
              Venue These Terms and any claim, cause of action, dispute or
              proceeding (“Claim”) arising out of or related to this Agreement,
              with the exception of the mutual agreement to arbitrate disputes,
              which shall be governed by the federal arbitration act, shall be
              governed by the laws of the State of Florida regardless of your
              country of origin or where you access the Services, and
              notwithstanding any conflicts of law principles. Subject to the
              arbitration terms and conditions set forth below, you agree that
              THE SOLE AND EXCLUSIVE FORUM AND REMEDY FOR ANY AND ALL DISPUTES
              AND CLAIMS RELATING IN ANY WAY TO OR ARISING OUT OF THIS AGREEMENT
              AND/OR THE SERVICES (INCLUDING YOUR USE OF AND ACCESS TO
              INSTAJOB’S MOBILE APPLICATIONS AND WEBSITES) SHALL BE FINAL AND
              BINDING ARBITRATION, except that to the extent that either party
              has in any manner infringed upon or violated or threatened to
              infringe upon or violate the other party’s intellectual property
              rights, or you have otherwise violated any of your use and access
              restrictions above, in which case the parties acknowledge and
              agree that arbitration is not an adequate remedy at law and that
              injunctive or other appropriate relief may be sought. 20.
              INDEMNITY 1. The User shall indemnify InstaJob, its affiliates,
              controlled companies, direct or indirect controlling shareholders,
              directors, officers, employees, representatives and employees,
              including attorney's fees, for any claims brought by other Users
              or third parties arising from the activities of the former in
              Platform, any breaches by it of the InstaJob Terms and other
              policies, or any breach by the User of law or the rights of third
              parties. 21. RESPONSIBILITIES AND DAMAGES. 1. In no event will we
              be liable for any failure to comply with this Agreement to the
              extent that such failure arises from factors outside our
              reasonable control. Without limitation of the foregoing, in the
              absence of our gross negligence or willful misconduct we will not
              be liable for any damages arising from the acts of hackers or
              similar bad actors interfering with the Platform or using or
              disclosing any of Your Content. 22. Compliance with Laws. 1. You
              will comply with all laws and regulations applicable to your
              activities under or in connection with this Agreement, including
              without limitation United States export control laws, regulations
              and executive orders. 23. UNDER AGE. 1. The Company does not
              knowingly collect or maintain personally identifiable information
              from persons under 18 years of age, and no part of the Platform is
              directed to persons under 18. If you are under the age of 18, you
              are not permitted to register as a User or to send personal
              information to Company. By accessing or using the Platform, you
              represent and warrant that you are 18 or older. 24. APPLICABLE
              LEGISLATION AND FORM OF ELECTION 1. All items of these Terms are
              governed by the laws in force in the United States of America. For
              all matters relating to the interpretation, compliance or any
              other inquiries related to these Terms, the parties agree to
              submit legally to the company address. Use of Our Services You
              agree that you won’t use the Services in any way that would
              violate any laws, infringe any rights of others (including
              intellectual property rights) or create liability for us. You also
              agree that you won’t collect or compile data on Workers using our
              Services except for the specific purpose of filling a job you list
              using our Services. To use our Services, you agree to give us
              truthful information about your business and the jobs you have
              available as well as the right to use that information to provide
              Services to you and make our Services available to Workers and
              other users. You agree that all information you post about your
              organization, any jobs, any Workers or any other information is
              truthful. Jobs or Gigs Postings In addition to any other
              applicable fees and applicable fees set forth in Instajob’s Online
              Services Agreement for Customers, you authorize us to charge your
              Account for the job posting and gig posting fees in effect or
              otherwise provided to you when you post a job or gig. Fees are in
              US dollars and you are responsible for any taxes on those fees. To
              the extent permitted by law, fees are non-refundable and
              non-cancelable. Account Control You agree to share your Account
              information only with your employees or representatives who are
              authorized to use our Services on your behalf. You agree that all
              activity on the Services using your account is being conducted by
              someone duly authorized by you to do so. You agree you are fully
              liable and responsible for all actions performed using your
              account. Compliance with Laws You agree to comply with all
              applicable laws relating to your use of our Services, including
              laws pertaining to non-discrimination and equal opportunity, as
              well as the Fair Credit Reporting Act.
            </p>
            <h2>Important trading terms</h2>
            <p>
              Instajob works as a channel of opportunities, and the best, with
              you professional instajob, always in charge of your business, in
              Instajob there is no registration fee, monthly fee and much less
              charge of any type of commission, if your service is approved ,
              100% of the value of the service is yours!
            </p>
            <p>
              Instajob is not responsible and is not involved in the negotiation
              between clients and professionals, nor is it responsible for the
              payment method agreed between client and professionals, Instajob
              is not responsible for verifying documentation, work permit,
              insurance and work license of the indicated professionals by the
              Platform for connecting clients and professionals, it is the full
              responsibility of the Instajob client to check with the
              professionals who contact them to pass a quote, if the
              professional has insurance and a work license, as well as full
              responsibility for making the payment directly to the
              professional, Instajob does not interfere and does not mediate the
              negotiation of values with professionals, it is the responsibility
              of the client to negotiate values directly with professionals and
              make the choice that will meet their needs.
            </p>
            <p>Instajob, April 2023</p>
          </Typography>
        </article>
      </Container>
    </section>
  );
};

import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  TextField,
  Grid,
  Hidden,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import PhoneNumberField from '../../components/phone-number-field/PhoneNumberField';
import beWorker from '../../assets/background-be-worker.jpg';
import { sendNotifications } from '../../api/services/authServices';
import { askForConfirmationCode } from '../../api/services/smsServices';

class ModalBeAWorker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phoneNumber: '',
      fullName: '',
      zipCode: '',
      password: '',
      confirmPassword: '',
      isRequesting: false,
      showConfirmationModal: false,
      termsAgreed: false,
      emailSmsConsent: false,
      showVerificationModal: false,
      verificationCode: '',
      isCodeIncorrect: false, // Adicione esta linha
      isResendingCode: false,
    };
  }

  onEmailChange = ({ target }) => {
    this.setState({
      email: target.value,
    });
  };

  onPhoneNumberChange = ({ target }) => {
    this.setState({
      phoneNumber: target.value,
    });
  };

  onFullNameChange = ({ target }) => {
    this.setState({
      fullName: target.value,
    });
  };

  onZipCodeChange = ({ target }) => {
    this.setState({
      zipCode: target.value,
    });
  };

  onPasswordChange = ({ target }) => {
    const password = target.value;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isPasswordValid = passwordRegex.test(password);
  
    this.setState({
      password: password,
      isPasswordValid: isPasswordValid
    });
  };

  onConfirmPasswordChange = ({ target }) => {
    this.setState({
      confirmPassword: target.value,
    });
  };

  _rediretTo = (url) => {
    window.location = url;
  };

  _showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  _hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  _handleTermsAgreementChange = (event) => {
    this.setState({ termsAgreed: event.target.checked });
  };

  _handleEmailSmsConsentChange = (event) => {
    this.setState({ emailSmsConsent: event.target.checked });
  };

  _sendNotifications = async () => {
    const { email, phoneNumber, emailSmsConsent, fullName, zipCode, password } = this.state;
    this.setState({
      isRequesting: true,
    });
    try {
      await sendNotifications(email, phoneNumber, fullName, zipCode, password);
    } catch (e) {
      // Handle notification sending errors
    }
    this.setState({
      isRequesting: false,
    });

    if (!this.state.termsAgreed) {
      this._showConfirmationModal();
      return;
    }

    this.props.handleClose();
  };

  _showVerificationModal = async () => {
    this.setState({ showVerificationModal: true });
    const { phoneNumber } = this.state;
    const { email } = this.state;
    const response = await askForConfirmationCode(phoneNumber.replace(/\D/g, ''), email);

    this.setState({ verificationData: response.replace(/\D/g, '') });
  };

  _resendConfirmationCode = async () => {
    const { phoneNumber } = this.state;
    const { email } = this.state;
  
    this.setState({ isResendingCode: true });
  
    try {
      const response = await askForConfirmationCode(phoneNumber.replace(/\D/g, ''), email);
      this.setState({ verificationData: response.replace(/\D/g, '') });
    } catch (error) {
      // Tratar erros ao reenviar o código
    }
  
    this.setState({ isResendingCode: false });
  };

  _hideVerificationModal = () => {
    this.setState({ showVerificationModal: false });
    this._hideConfirmationModal();
  };

  _verifyCode = () => {
    const { verificationCode, verificationData } = this.state;
  
    if (verificationCode === verificationData) {
      this._hideVerificationModal();
      this.props.handleClose();
      this._sendNotifications();
    } else {
      this.setState({ isCodeIncorrect: true }); // Set isCodeIncorrect to true if the code is incorrect
    }
  };

  isPasswordValid = () => {
    const { password } = this.state;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  
  isPasswordMatch = () => {
    const { password, confirmPassword } = this.state;
    return password === confirmPassword && this.isPasswordValid();
  };

  render() {
    const { show, handleClose } = this.props;
    const {
      email,
      phoneNumber,
      fullName,
      zipCode,
      password,
      confirmPassword,
      isRequesting,
      showConfirmationModal,
      termsAgreed,
      emailSmsConsent,
      showVerificationModal,
      verificationCode,
      isCodeIncorrect, 
      isResendingCode
    } = this.state;

    return (
      <React.Fragment>
        <Dialog maxWidth="sm" open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Get more customers directly on your phone</DialogTitle>
          <DialogContent>
            <Typography variant="body1" style={{ marginBottom: 15 }}>
              Receive free demands and pay only for their contact number.
            </Typography>
            <Grid container>
              <Grid
                item
                sm={12}
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <TextField
                  autoFocus
                  id="fullName"
                  variant="outlined"
                  label="Full Name"
                  type="text"
                  onChange={this.onFullNameChange}
                  value={fullName}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  id="email"
                  variant="outlined"
                  label="Email Address"
                  type="email"
                  onChange={this.onEmailChange}
                  value={email}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  id="phoneNumber"
                  variant="outlined"
                  label="Phone Number"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  InputProps={{ inputComponent: PhoneNumberField }}
                  onChange={this.onPhoneNumberChange}
                  value={phoneNumber}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  id="zipCode"
                  variant="outlined"
                  label="Zip Code"
                  type="text"
                  onChange={this.onZipCodeChange}
                  value={zipCode}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  id="password"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={this.onPasswordChange}
                  value={password}
                  fullWidth
                />
                <TextField
                margin="normal"
                id="confirmPassword"
                variant="outlined"
                label="Confirm Password"
                type="password"
                error={!this.isPasswordMatch()}
                helperText={!this.isPasswordMatch() && "Passwords do not match"}
                onChange={this.onConfirmPasswordChange}
                value={confirmPassword}
                fullWidth
                />
                {!this.isPasswordMatch() && !this.isPasswordValid() && (
                <Typography variant="body2" color="error">
                    Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.
                </Typography>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailSmsConsent}
                      onChange={this._handleEmailSmsConsentChange}
                      name="emailSmsConsent"
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I agree to receive notifications via Whatsapp and email for Instajob user registration. 
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this._showConfirmationModal}
              disabled={isRequesting || !emailSmsConsent || !this.isPasswordMatch()}
            >
              Register
              {isRequesting && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation dialog */}
        <Dialog
          maxWidth="sm"
          open={showConfirmationModal}
          onClose={this._hideConfirmationModal}
          aria-labelledby="confirmation-dialog-title"
        >
          <DialogTitle id="confirmation-dialog-title">I Agree</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              By proceeding, you affirm that you have read and agree to our{' '}
              <a href="https://instajob.work/terms" target="_blank">
                terms of acceptance
              </a>
              .
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={this._handleTermsAgreementChange}
                  name="termsAgreed"
                  color="primary"
                />
              }
              label="I confirm acceptance of the terms."
            />
             <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
              Code will be sent via WhatsApp.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._hideConfirmationModal}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this._showVerificationModal}
              disabled={!termsAgreed}
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>

        {/* Verification dialog */}
        <Dialog
  maxWidth="sm"
  open={showVerificationModal}
  onClose={this._hideVerificationModal}
  aria-labelledby="verification-dialog-title"
>
  <DialogTitle id="verification-dialog-title">Verification</DialogTitle>
  <DialogContent>
    <Typography variant="body1" gutterBottom>
      Please enter the verification code that was sent to you.
    </Typography>
    <TextField
      autoFocus
      id="verificationCode"
      variant="outlined"
      label="Verification Code"
      type="text"
      onChange={(event) => this.setState({ verificationCode: event.target.value, isCodeIncorrect: false })}
      value={verificationCode}
      error={isCodeIncorrect} // Add error prop based on isCodeIncorrect state
      helperText={isCodeIncorrect && "Incorrect verification code"} // Add helper text for error
      fullWidth
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={this._hideVerificationModal}>Cancel</Button>
    <Button variant="contained" color="primary" onClick={this._verifyCode}>
      Verify
    </Button>
    <Button
        variant="outlined"
        color="primary"
        onClick={this._resendConfirmationCode}
        disabled={isResendingCode}
      >
        Resend Code
        {isResendingCode && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
      </Button>
  </DialogActions>
</Dialog>
      </React.Fragment>
    );
  }
}

export default ModalBeAWorker;
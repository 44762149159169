import React, { Component } from "react";
import { Typography, Container, Grid } from "@material-ui/core";

import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";

export const Privacy = (props) => {
  const { width: windowWidth } = useWindowDimensions();
  return (
    <section
      style={{
        background: "linear-gradient(to bottom, #d2d2d2, transparent)",
        color: "black",
        paddingTop: windowWidth > 960 ? 155 : 80,
      }}
    >
      <Container
        style={{
          textAlign: "justify",
          width: "70%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="inherit">
          InstaJob Privacy Policy{" "}
        </Typography>
        <article>
          <Typography variant="body2" color="inherit" style={{ marginTop: 15 }}>
            <h2>INFORMATION PRIVACY</h2>

            <p>
              INSTAJOB WORL LLC (“Instajob”), a limited liability company,
              Document Number L17000237580 / FEI / EIN Number 82-3456684, is a
              legal entity governed by private law that provides online
              advertising services, carried out between promissory contractors
              (“Contractor”) and promissory Service Providers (“Providers”),
              through the app and the Instajob website (“Platform”).
            </p>

            <p>
              Through this Privacy Policy (“Privacy Policy”), Instajob clarifies
              to users in general, Contractors and Providers (all collectively
              referred to as “Users”) its practices with regard to the privacy
              of information provided by Users, in addition to demonstrating our
              commitment to safeguard your privacy and protect your personal
              data.
            </p>

            <p>
              i. All information or personal data provided by the Instajob User
              is stored on highly secure servers or magnetic media.{" "}
            </p>

            <p>
              ii. Instajob will take all possible measures to maintain the
              confidentiality and security described in this clause, but will
              not be responsible for damage that may be derived from the
              violation of these measures by third parties who use improper,
              fraudulent or illegal means to access the information stored in
              the servers or in the databases used by Instajob.
            </p>

            <p>
              iii. Instajob collects the following data and personal information
              from its Users:
            </p>
            <ol type="i">
              <li>Full name;</li>
              <li>Cell phone number;</li>
              <li>Email;</li>
              <li>CEP;</li>
              <li>Geolocation based on IP address;</li>
              <li>IP address (Internet Protocol);</li>
            </ol>
            <p>
              iv. This information is collected when the User enters it on the
              platform and is stored and processed by Instajob for the following
              purposes:
            </p>
            <ol type="i">
              <li>
                Registration maintenance necessary for the provision of online
                advertisement services;
              </li>
              <li>User Identification on the Platform and Application;</li>
              <li>Enable the User to use the Platform and Application;</li>
              <li>Location of service providers close to the User;</li>
              <li>
                Fraud prevention and implementation of platform and application
                security measures;
              </li>
              <li>
                Communication regarding points that may be of interest to the
                User and that are related to Instajob's advertising services;
              </li>
              <li>
                Provision of support and assistance services requested by the
                User;
              </li>
              <li>
                Conducting research on the use and interaction of the Platform
                and Application for improvement of the ad services offered by
                Instajob;
              </li>
              <li>
                Generation of statistical reports regarding the use and
                interaction of Users;
              </li>
              <li>
                Promotion of Instajob to inform the User about news,
                functionalities, contents, news and other events relevant to the
                maintenance of this relationship. Such communications are
                carried out through automatic message sending tools created from
                customized processes based on the User's history;
              </li>
              <li>
                Comply with legal and regulatory obligations to which Instajob
                is subject;
              </li>
              <li>
                Protect Instajob from its rights and obligations related to the
                Platform and the Application, pursuant to the provisions of
                Brazilian law;
              </li>
              <li>
                Collaborate and/or comply with court order or request by
                administrative authority.
              </li>
            </ol>
            <p>
              v. During the User's navigation on the platform and in the
              application, Instajob also collects the following information and
              data from Users:{" "}
            </p>
            <ol type="i">
              <li>
                Access and actions performed by the User on the Platform and
                Application;
              </li>
              <li>
                Pages and screens accessed by the User on the Platform and
                Application;
              </li>
              <li>
                Dates and times of each User action on the Platform and
                Application;
              </li>
              <li>
                Information about the device and browser used by the User;
              </li>
              <li>Session and User ID, when available.</li>
            </ol>
            <p>
              The data and information described in this item are collected
              through cookies. These are files that gather User information from
              their access to the platform.{" "}
            </p>
            <p>
              vi. The Users' personal data and information collected may be
              shared with Instajob partners when necessary for the same purposes
              described above.
            </p>
            <p>
              vii. Instajob does not have access to the content of links or
              communications between Users.
            </p>
            <p>
              viii. Instajob, for legal reasons, will keep in its database all
              information collected from Users who delete their registrations
              that are required by law, such as items referring to invoices.
            </p>
            <p>
              ix. Each User's nickname and password serve to guarantee privacy
              and security. Instajob advises its Users not to share this
              information with anyone. Instajob is not responsible for damages
              caused to the User by sharing this information.
            </p>
            <p>
              x. All information provided by Users to Instajob is protected by
              specialized companies.
            </p>
            <p>
              xi. Instajob collects and stores all information provided by its
              users while using its platform, including at the time of
              registration, for its own use and that of its business partners.
            </p>
            <p>
              xii. Instajob will provide all information requested by public
              bodies, provided that it is duly justified and compatible with the
              law in force.
            </p>
            <p>
              xiii. Instajob uses cookies and monitoring software for its Users
              to provide its Users with the best possible navigation, based on
              their needs, and for internal research. These cookies do not
              collect personal information, they only inform usage and browsing
              preferences of each User, in addition to providing Instajob with
              statistics and data to improve its services.
            </p>
            <p>
              xiv. In case of suspected or actual violation of Users' personal
              data, Instajob undertakes to notify the affected Users.
            </p>
            <p>
              xv. In the event of changes or amendments to this Privacy Policy,
              Instajob undertakes to notify Users through its platform.
            </p>
            <p>
              xvi. In accordance with the General Data Protection Law, the User
              may request:
            </p>

            <ol type="i">
              <li>
                Access to Personal Data: guarantees the User to receive a copy
                of all his Data in our database.
              </li>
              <li>
                Confirmation of Processing of Personal Data: guarantees the User
                confirmation that we are Processing their Personal Data.
              </li>
              <li>
                Revocation of consent: guarantees the User the possibility of
                revoking the previously demonstrated consent.
              </li>
              <li>
                Portability: possibility of providing the User or third parties
                indicated by the User with Personal Data in a structured format,
                provided that the commercial secret of our products is
                respected;
              </li>
              <li>
                Action: makes it possible to request, at any time, the
                rectification of any Personal Data, if they are incomplete,
                inaccurate or outdated, which are in the care of Instajob;
              </li>
              <li>
                Deletion: allows you to request the deletion of your Personal
                Data, even if the User has given prior consent. Upon
                acquisition, all data that is not relevant to the provision of
                our services (such as, for example, Data that is necessary for
                compliance with a legal obligation), will be deleted.
              </li>
            </ol>
            <h2>Important trading terms </h2>
            <p>
              Instajob works as a channel of opportunities, and the best, with
              you professional instajob, always in charge of your business, in
              Instajob there is no registration fee, monthly fee and much less
              charge of any type of commission, if your service is approved ,
              100% of the value of the service is yours!{" "}
            </p>
            <p>
              Instajob is not responsible and is not involved in the negotiation
              between clients and professionals, nor is it responsible for the
              payment method agreed between client and professionals, Instajob
              is not responsible for verifying documentation, work permit,
              insurance and work license of the indicated professionals by the
              Platform for connecting clients and professionals, it is the full
              responsibility of the Instajob client to check with the
              professionals who contact them to pass a quote, if the
              professional has insurance and a work license, as well as full
              responsibility for making the payment directly to the
              professional, Instajob does not interfere and does not mediate the
              negotiation of values with professionals, it is the responsibility
              of the client to negotiate values directly with professionals and
              make the choice that will meet their needs.{" "}
            </p>
          </Typography>
        </article>
      </Container>
    </section>
  );
};

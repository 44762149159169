import React from 'react';
import { useHistory } from 'react-router-dom';
import { List, ListItemText, ListItem, Card, CardContent, CardHeader } from '@material-ui/core';
import { ArrowForward, Title } from '@material-ui/icons';
import useWindowDimensions from '../0 - Shared/hooks/useWindowDimensions';

export const ChooseSubcategories = ({ subcategories, category }) => {
    const { push: redirectTo } = useHistory();
    const { width: windowWidth } = useWindowDimensions();
    
    return (
        <Card style={{ width: windowWidth > 960 ? '65%' : '100%', marginTop: windowWidth > 960 ? -100 : 15 }}>
            <CardHeader style={{ color: '#727272', textAlign: 'center' }} title={`What service are you needing for ${category}?`} />
            <CardContent>
                <List>
                    {
                        subcategories.map((subcat) => {
                            return (
                                <ListItem button key={subcat.id} onClick={() => redirectTo(`/${subcat.name}`)}>
                                    <ListItemText>
                                        {subcat.name}
                                    </ListItemText>
                                    <ArrowForward color="primary" />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </CardContent>
        </Card>
    );
};
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../0 - Shared/hooks/useWindowDimensions";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  useCategoriesFilter,
} from "./hooks";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Modal,
} from "@material-ui/core";
import axios from "axios";

export const DemandsAvailableScreenAdd = () => {
  const { width: windowWidth } = useWindowDimensions();

  const [creationDate, setCreationDate] = useState(new Date().toISOString());
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [city, setCity] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [description, setDescription] = useState("");
  const [isFullTime, setIsFullTime] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [price, setPrice] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const history = useHistory();

  const useStyles = makeStyles((theme) =>
    createStyles({
      modal: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    })
  );

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    width: "30%",
    height: "30%",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  };

  const estadosEUA = [
    { nome: "Alabama", sigla: "AL" },
    { nome: "Alaska", sigla: "AK" },
    { nome: "Arizona", sigla: "AZ" },
    { nome: "Arkansas", sigla: "AR" },
    { nome: "Califórnia", sigla: "CA" },
    { nome: "Colorado", sigla: "CO" },
    { nome: "Connecticut", sigla: "CT" },
    { nome: "Delaware", sigla: "DE" },
    { nome: "Flórida", sigla: "FL" },
    { nome: "Geórgia", sigla: "GA" },
    { nome: "Havaí", sigla: "HI" },
    { nome: "Idaho", sigla: "ID" },
    { nome: "Illinois", sigla: "IL" },
    { nome: "Indiana", sigla: "IN" },
    { nome: "Iowa", sigla: "IA" },
    { nome: "Kansas", sigla: "KS" },
    { nome: "Kentucky", sigla: "KY" },
    { nome: "Louisiana", sigla: "LA" },
    { nome: "Maine", sigla: "ME" },
    { nome: "Maryland", sigla: "MD" },
    { nome: "Massachusetts", sigla: "MA" },
    { nome: "Michigan", sigla: "MI" },
    { nome: "Minnesota", sigla: "MN" },
    { nome: "Mississippi", sigla: "MS" },
    { nome: "Missouri", sigla: "MO" },
    { nome: "Montana", sigla: "MT" },
    { nome: "Nebraska", sigla: "NE" },
    { nome: "Nevada", sigla: "NV" },
    { nome: "Nova Hampshire", sigla: "NH" },
    { nome: "Nova Jérsei", sigla: "NJ" },
    { nome: "Novo México", sigla: "NM" },
    { nome: "Nova York", sigla: "NY" },
    { nome: "Carolina do Norte", sigla: "NC" },
    { nome: "Dakota do Norte", sigla: "ND" },
    { nome: "Ohio", sigla: "OH" },
    { nome: "Oklahoma", sigla: "OK" },
    { nome: "Oregon", sigla: "OR" },
    { nome: "Pensilvânia", sigla: "PA" },
    { nome: "Rhode Island", sigla: "RI" },
    { nome: "Carolina do Sul", sigla: "SC" },
    { nome: "Dakota do Sul", sigla: "SD" },
    { nome: "Tennessee", sigla: "TN" },
    { nome: "Texas", sigla: "TX" },
    { nome: "Utah", sigla: "UT" },
    { nome: "Vermont", sigla: "VT" },
    { nome: "Virgínia", sigla: "VA" },
    { nome: "Washington", sigla: "WA" },
    { nome: "West Virginia", sigla: "WV" },
    { nome: "Wisconsin", sigla: "WI" },
    { nome: "Wyoming", sigla: "WY" },
  ];

  const handleCreateDemand = async () => {
    const newDemand = {
      CreationDate: creationDate,
      Category: category,
      Subcategory: subcategory,
      City: city,
      StateCode: stateCode,
      Description: description,
      IsFullTime: isFullTime,
      Requirements: requirements,
      Price: price,
      ZipCode: zipCode,
    };

    try {
      const response = await axios.post(
        "https://api.instajob.work/api/demands",
        newDemand,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}`,
          },
        }
      );
      console.log(response);
      if (response.data.success) {
        setModalMessage("success");
        setOpenModal(true);
      } else {
        setModalMessage("error");
        setOpenModal(true);
      }
    } catch (error) {
      console.error(error);
      setModalMessage("error");
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    if (modalMessage === "success") {
      history.push("/demands-available");
    }
  };

  const { categories, subcategories } = useCategoriesFilter(category);

  return (
    <section
      style={{
        background: "linear-gradient(to bottom, #d2d2d2, transparent)",
        color: "white",
        paddingTop: windowWidth > 960 ? 155 : 80,
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="primary"
              style={{ textAlign: "center" }}
            >
              Add Demand
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Category"
              value={category}
              onChange={({ target }) => {
                setCategory(target.value);
                setSubcategory("");
              }}
              style={{ textAlign: "left" }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={""} style={{ color: "#424242" }}>
                Select a Category...
              </MenuItem>
              {categories.map(({ name }, i) => (
                <MenuItem key={i} style={{ color: "#424242" }} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Subcategory"
              value={subcategory}
              onChange={({ target }) => {
                setSubcategory(target.value);
              }}
              style={{ textAlign: "left" }}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={""} style={{ color: "#424242" }}>
                Select a Subcategory...
              </MenuItem>
              {subcategories.map(({ name }, i) => (
                <MenuItem key={i} style={{ color: "#424242" }} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth margin="none">
              <InputLabel id="stateCode-label">State code</InputLabel>
              <Select
                labelId="stateCode-label"
                id="stateCode"
                value={stateCode}
                onChange={(event) => setStateCode(event.target.value)}
                label="State code"
              >
                {estadosEUA.map((estado) => (
                  <MenuItem key={estado.sigla} value={estado.sigla}>
                    {estado.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              margin="none"
              id="city"
              label="City"
              type="text"
              fullWidth
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              margin="none"
              id="description"
              label="Description"
              type="text"
              fullWidth
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              margin="none"
              id="price"
              label="Price"
              type="number"
              fullWidth
              value={price}
              onChange={(event) => setPrice(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              margin="none"
              id="zipCode"
              label="Zip code"
              type="text"
              fullWidth
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              margin="none"
              id="requirements"
              label="Requirements"
              type="text"
              fullWidth
              value={requirements}
              onChange={(event) =>
                setRequirements(event.target.value.split(","))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="full-time-select"
              select
              label="Full time"
              value={isFullTime}
              onChange={(event) => setIsFullTime(event.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateDemand}
            >
              Create Demand
            </Button>
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={modalStyle}>
            {modalMessage === "success" ? (
              <p style={{ color: "black" }}>Demand added successfully!</p>
            ) : (
              <p style={{ color: "black" }}>
                Failed to add demand. Please try again.
              </p>
            )}
            <Button onClick={handleModalClose}>OK</Button>
          </div>
        </Modal>
      </Container>
    </section>
  );
};

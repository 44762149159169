import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCommonDemandsPaged, getGoldenDemandsPaged } from '../../api/services/demandsServices';
import { distinct } from '../../helpers';
import { changeCityFilter, changeStateFilter } from '../../redux/modules/filter/filterActions'

export const useFiltroCommonDemands = (state, city, category, subcategory, search, pageIndex) => {
    const { requesting, list } = useSelector(states => ({
        requesting: states.demands.isRequestingCommonDemandsPaged,
        list: states.demands.commonDemandsPaged
    }));

    useEffect(() => {
        getCommonDemandsPaged(state, city, category, subcategory, search, pageIndex)
    }, [state, city, category, subcategory, search, pageIndex, getCommonDemandsPaged]);

    return { requestingCommonDemand: requesting, commonDemandsPagedList: list };
}

export const useFiltroGoldenDemands = (state, city, category, subcategory, search, pageIndex) => {
    const { requesting, list } = useSelector(states => ({
        requesting: states.demands.isRequestingGoldenDemandsPaged,
        list: states.demands.goldenDemandsPaged
    }));

    useEffect(() => {
        getGoldenDemandsPaged(state, city, category, subcategory, search, pageIndex)
    }, [state, city, category, subcategory, search, pageIndex, getGoldenDemandsPaged]);

    return { requestingGoldenDemand: requesting, goldenDemandsPagedList: list };
}

export const useCityStateFilter = () => {
    const dispatch = useDispatch();
    const { cityFilter, stateFilter, demands } = useSelector(states => ({
        cityFilter: states.filter.cityFilter,
        stateFilter: states.filter.stateFilter,
        demands: states.demands.allDemands
    }));

    const states = useMemo(() => {
        return distinct(demands            
            .map((demand) => ({
                city: demand.city,
                stateCode: demand.stateCode,
                state: demand.state
            })), "state")
            .sort((a, b) => {
                if (a.state < b.state)
                    return -1;
                if (a.state > b.state)
                    return 1;
                return 0;
            });
    }, [demands]);

    const cities = useMemo(() => {
        return distinct(demands
            .filter(x => (x.stateCode === stateFilter) &&
                x.city.includes(cityFilter))
            .map((demand) => ({
                city: demand.city,
                stateCode: demand.stateCode,
                state: demand.state
            })), "city");
    }, [stateFilter, cityFilter, demands]);

    const setSelectedState = (state) => {
        dispatch(changeCityFilter(''));
        dispatch(changeStateFilter(state));
    }

    const setSelectedCity = (city) => {
        dispatch(changeCityFilter(city));
    }

    return { selectedCity: cityFilter, selectedState: stateFilter, filteredCities: cities, states, setSelectedCity, setSelectedState };
}

export const useCategoriesFilter = (selectedCategory) => {
    const { categories } = useSelector(states => ({ categories: states.categories.allCategories }));
    const subcategories = useMemo(() => {
        if (Boolean(selectedCategory)) {
            return categories.filter(cat => cat.name.toUpperCase() === selectedCategory.toUpperCase())[0].subcategories;
        }
        return [];
    }, [selectedCategory]);
    return { categories, subcategories };
}
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store, { history } from './redux/globalStore';

import { theme } from './theme';
import SuccessSnackbar from './components/snackbars/SuccessSnackbar';
import WarningSnackbar from './components/snackbars/WarningSnackbar';
import DangerSnackbar from './components/snackbars/DangerSnackbar';
import DefaultSnackbar from './components/snackbars/DefaultSnackbar';
import { PrincipalScreen } from './features/Principal';

const MainApplication = () => {
    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <PrincipalScreen />
                    <SuccessSnackbar />
                    <WarningSnackbar />
                    <DangerSnackbar />
                    <DefaultSnackbar />
                </ThemeProvider>
            </BrowserRouter>
        </ReduxProvider>
    )
}

ReactDOM.render(<MainApplication />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
